<template>
  <div class="flex flex-col items-center justify-center p-5">
    <h2
      class="text-2xl text-gray-900 dark:text-gray-200 font-medium mb-4 mr-48"
    >
      Bank Details
    </h2>
    <notifications group="foo" />
    <form
      @submit.prevent="updatePayment"
      class="w-full sm:w-3/4 md:w-2/3 lg:w-1/2"
    >
      <div
        class="mb-4 flex flex-col sm:flex-row items-center sm:justify-start sm:space-x-4"
      >
        <label
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3 mb-2 sm:mb-0 sm:mr-2 w-[150px]"
          for="account"
          >Account Name</label
        >
        <input
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3"
          id="account"
          v-model="payment.account"
          type="text"
        />
      </div>
      <div
        class="mb-4 flex flex-col sm:flex-row items-center sm:justify-start sm:space-x-4"
      >
        <label
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3 mb-2 sm:mb-0 sm:mr-2 w-[150px]"
          for="iban"
          >IBAN</label
        >
        <input
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3"
          id="iban"
          v-model="payment.iban"
          type="text"
        />
      </div>
      <div
        class="mb-4 flex flex-col sm:flex-row items-center sm:justify-start sm:space-x-4"
      >
        <label
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3 mb-2 sm:mb-0 sm:mr-2 w-[150px]"
          for="swift"
          >SWIFT/BIC</label
        >
        <input
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3"
          id="swift"
          v-model="payment.swift"
          type="text"
        />
      </div>
      <div
        class="mb-4 flex flex-col sm:flex-row items-center sm:justify-start sm:space-x-4"
      >
        <label
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3 mb-2 sm:mb-0 sm:mr-2 w-[150px]"
          for="bank_name"
          >Bank Name</label
        >
        <input
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3"
          id="bank_name"
          v-model="payment.bank_name"
          type="text"
        />
      </div>
      <div
        class="mb-4 flex flex-col sm:flex-row items-center sm:justify-start sm:space-x-4"
      >
        <label
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3 mb-2 sm:mb-0 sm:mr-2 w-[150px]"
          for="bank_address"
          >Bank Address</label
        >
        <input
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3"
          id="bank_address"
          v-model="payment.bank_address"
          type="text"
        />
      </div>
      <div
        class="mb-4 flex flex-col sm:flex-row items-center sm:justify-start sm:space-x-4"
      >
        <label
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3 mb-2 sm:mb-0 sm:mr-2 w-[150px]"
          for="bank_city"
          >Bank City</label
        >
        <input
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3"
          id="bank_city"
          v-model="payment.bank_city"
          type="text"
        />
      </div>
      <div class="flex justify-center mt-6 mr-48">
        <button
          class="bg-[#318bc8] hover:bg-[#318bc8] text-white font-bold py-2 px-4 rounded"
          type="submit"
        >
          Update
        </button>
      </div>
    </form>
    <div v-if="updated" class="mt-3 text-green-500">
      Your profile has been updated.
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfileComponent",
  data() {
    return {
      updated: false,
      payment: JSON.parse(localStorage.getItem("payment")) || {
        account: "Tisalabs ltd",
        iban: "45678899322335678",
        swift: "BOIIE22",
        bank_name: "Bank Of Ireland",
        bank_address: "Main Street ",
        bank_city: "Midleton",
      },
    };
  },
  methods: {
    updatePayment() {
      try {
        localStorage.setItem("payment", JSON.stringify(this.payment));
        this.$notify({
          group: "foo",
          type: "success",
          title: "Success",
          text: "Your Bank Details has been updated successfully.",
        });
        this.updated = true;
      } catch (e) {
        this.$notify({
          group: "foo",
          type: "error",
          title: "Error",
          text: "An error occurred while updating your Bank Details.",
        });
        this.updated = false;
      }
    },
  },
};
</script>
