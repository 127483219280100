const actions = {
    setUser(context, payload) {
      context.commit("setUser", payload);
    },
    setUserGoogle(context, payload) {
      context.commit("setUserGoogle", payload);
    },
    setDevices(context, payload) {
      context.commit("setDevices", payload);
    },
    setRememberedEmail({ commit }, payload) {
      commit("setRememberedEmail", payload);
      // Save to local storage for persistence
      localStorage.setItem("rememberedEmail", payload.email);
      localStorage.setItem("rememberedEmailExpiration", payload.expiration);
    },
    clearRememberedEmail({ commit }) {
      commit("setRememberedEmail", { email: null, expiration: null });
      localStorage.removeItem("rememberedEmail");
      localStorage.removeItem("rememberedEmailExpiration");
    },
    // Action to set deviceDetailsInfo
    setDeviceDetailsInfo({ commit }, device) {
      commit('setDeviceDetailsInfo', device);
    },
  };
  
  export default actions;