import checkToken from "@/jwt";
import {getDevicesQueries} from "@/store/store";

async function getDevicesByUser(id, apollo) {
    try {
        const { data } = await apollo.query({
            query: getDevicesQueries.getDevicesQuery,
            variables: {
                userId: id
            }
        });
        const devicesData = data.devices.devices;
        return devicesData;
    } catch (error) {
        console.error('Error in getDevices by User query => ', error);
        return [];
    }
}

async function getDevicesByOperator(id, apollo) {
    try {
        const { data } = await apollo.query({
            query: getDevicesQueries.getDevicesByOperatorQuery,
            variables: {
                operatorId: id
            }
        });
        const devicesData = data.devices.devices;
        return devicesData;
    } catch (error) {
        console.error('Error in getDevices by Operator query => ', error);
        return [];
    }
}

async function getDevicesByUserWithMessages(id, apollo) {
    try {
        const { data } = await apollo.query({
            query: getDevicesQueries.getDevicesByUserWithMessages,
            variables: {
                userId: id
            }
        });
        const devicesData = data.devices.devices;
        return devicesData;
    } catch (error) {
        console.error('Error in getDevices by User query => ', error);
        return [];
    }
}

async function getDevicesByOperatorWithMessages(id, apollo) {
    try {
        const { data } = await apollo.query({
            query: getDevicesQueries.getDevicesByOperatorWithMessages,
            variables: {
                operatorId: id
            }
        });
        const devicesData = data.devices.devices;
        return devicesData;
    } catch (error) {
        console.error('Error in getDevices by Operator query => ', error);
        return [];
    }
}

export default async function getDevices(apollo, router, id, userType, messages = false) {
    try {
        await checkToken(apollo, router);
        let devices = [];
        if (userType === "operator") {
            if (messages) {
                devices = await getDevicesByOperatorWithMessages(id, apollo);
            } else {
                devices = await getDevicesByOperator(id, apollo);
            }
        } else {
            if (messages) {
                devices = await getDevicesByUserWithMessages(id, apollo);
            } else {
                devices = await getDevicesByUser(id, apollo);
            }
        }
        return devices;
    } catch (error) {
        console.error("Error in getDevices function:", error);
        return [];
    }
}