<template>
  <div class="flex flex-col items-center justify-center p-5">
    <h2
      class="text-2xl text-gray-900 dark:text-gray-200 font-medium mb-4 mr-48"
    >
      Operator
    </h2>
    <div class="w-full sm:w-3/4 md:w-2/3 lg:w-1/2">
      <div
        class="mb-4 flex flex-col sm:flex-row items-center sm:justify-start sm:space-x-4"
      >
        <label
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3 mb-2 sm:mb-0 sm:mr-2 w-[145px] whitespace-nowrap"
          for="operator_name"
        >
          Operator Name
        </label>
        <input
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] mr-48 text-white py-2 px-3 mr-36"
          id="operator_name"
          type="text"
          value="MPower"
          readonly
          unselectable="on"
          style="cursor: default"
        />
      </div>
      <div
        class="mb-4 flex flex-col sm:flex-row items-center sm:justify-start sm:space-x-4"
      >
        <label
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3 mb-2 sm:mb-0 sm:mr-2 min-w-[145px] max-w-[200px] whitespace-nowrap"
          for="contact_details"
        >
          Contact Details
        </label>
        <input
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3"
          id="contact_details"
          type="text"
          value="Guy Sale"
          readonly
          unselectable="on"
          style="cursor: default"
        />
        <input
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3"
          id="contact_details2"
          type="text"
          value="guy@mpower.com"
          readonly
          unselectable="on"
          style="cursor: default"
        />
      </div>
      <div
        class="mb-4 flex flex-col sm:flex-row items-center sm:justify-start sm:space-x-4"
      >
        <label
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3 mb-2 sm:mb-0 sm:mr-2 w-[145px] whitespace-nowrap"
          for="technical_details"
        >
          Technical Details
        </label>
        <input
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3"
          id="technical_details"
          type="text"
          value="Mary Support"
          readonly
          unselectable="on"
          style="cursor: default"
        />
        <input
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3"
          id="technical_details2"
          type="text"
          value="mary@mpower.com"
          readonly
          unselectable="on"
          style="cursor: default"
        />
      </div>
      <div
        class="mb-4 flex flex-col sm:flex-row items-center sm:justify-start sm:space-x-4"
      >
        <label
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3 mb-2 sm:mb-0 sm:mr-2 w-[145px] whitespace-nowrap"
          for="support"
        >
          Support
        </label>
        <input
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3 mr-36"
          id="support "
          type="text"
          value="0035321456i38"
          readonly
          unselectable="on"
          style="cursor: default"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OperatorComponent",
};
</script>

<style></style>
