<template>
  <div class="flex flex-col items-center justify-center p-5">
    <h2 class="text-2xl text-gray-900 dark:text-gray-200 font-medium mb-4">
      Trident Power Generation Return On Investment
    </h2>
    <div class="w-full sm:w-3/4 md:w-2/3 lg:w-1/2">
      <div
        class="mb-4 flex flex-col sm:flex-row items-center sm:justify-start sm:space-x-4"
      >
        <label
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3 mb-2 sm:mb-0 sm:mr-2 w-[105px] whitespace-nowrap"
          for="energyIn"
        >
          Energy In
        </label>
        <input
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3"
          id="energyIn"
          type="text"
          value="512 KW"
          readonly
          unselectable="on"
          style="cursor: default"
        />
        <input
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3"
          id="energyInSecond"
          type="text"
          value="354.95 €"
          readonly
          unselectable="on"
          style="cursor: default"
        />
      </div>
      <div
        class="mb-4 flex flex-col sm:flex-row items-center sm:justify-start sm:space-x-4"
      >
        <label
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3 mb-2 sm:mb-0 sm:mr-2 w-[105px] whitespace-nowrap"
        >
          Energy Out
        </label>
        <input
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3"
          id="energyOut"
          type="text"
          value="600 KW"
          readonly
          unselectable="on"
          style="cursor: default"
        />
        <input
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3"
          id="energyOutSecond"
          type="text"
          value="768.54 €"
          readonly
          unselectable="on"
          style="cursor: default"
        />
      </div>
      <div
        class="mb-4 flex flex-col sm:flex-row items-center sm:justify-start sm:space-x-4"
      >
        <label
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3 mb-2 sm:mb-0 sm:mr-2 w-[105px] whitespace-nowrap"
          for="revenue"
        >
          Revenue
        </label>
        <input
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3"
          id="revenue"
          type="text"
          value="413.59 Euro"
          readonly
          unselectable="on"
          style="cursor: default"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BillingComponent",
};
</script>

<style></style>
