<template>
  <Preloader v-if="loading" />

  <div>
    <div class="flex items-center justify-between mt-2 w-full">
      <h1
        class="text-2xl text-gray-900 dark:text-gray-200 font-medium py-3 mr-4 mt-4 px-4 min-w-[150px]"
      >
        Message
      </h1>
    </div>
    <div
      class="card bg-white dark:bg-gray-800 rounded-md p-5 border dark:border-gray-700 flex m-2 mt-6 mb-6 mx-6 px-4"
    >
      <div class="flex flex-col w-full">
        <!-- Filter Controls -->
        <div class="flex space-between">
          <h2 class="mb-4" style="color: white">Messages</h2>
          <div class="flex items-center custom--grid--layout">
            <select
              @change="filterMessages"
              v-model="filters.device"
              class="drop-down p-2 rounded-md text-sm font-medium text-gray-700"
              style="background-color: #02ff00; color: black"
            >
              <option
                v-for="device in devices"
                :key="device.id"
                :value="device.id"
              >
                {{ device.name }}
              </option>
            </select>
          </div>
          <select
            @change="filterMessages"
            v-model="filters.severity"
            class="drop-down p-2 rounded-md text-sm font-medium text-gray-700"
            style="background-color: #02ff00; color: black"
          >
            <option value="">All Severities</option>
            <option
              v-for="severity in severities"
              :key="severity.id"
              :value="severity.id"
            >
              {{ severity.name }}
            </option>
          </select>
          <select
            @change="filterMessages"
            v-model="filters.type"
            class="drop-down p-2 rounded-md text-sm font-medium text-gray-700"
            style="background-color: #02ff00; color: black"
          >
            <option
              v-for="msgType in types"
              :key="msgType.id"
              :value="msgType.id"
            >
              {{ msgType.name }}
            </option>
          </select>
        </div>
        <DataTable
          :value="filteredMessages"
          :rowStyle="rowStyle"
          :rowClass="rowClasses"
          tableStyle="min-width: 5px"
        >
          <Column field="devices.name" header="Device Name"></Column>
          <Column
            field="severity"
            header="Severity"
            class="column-status"
          ></Column>
          <Column
            field="messageText"
            header="Message"
            class="column-health"
            :bodyStyle="{ width: '500px' }"
          ></Column>
          <Column field="messageType" header="Message Type"></Column>
          <Column field="sentAt" header="Sent At">
            <template #body="{ data }">
              {{ new Date(data["sentAt"]).toLocaleString() }}
            </template>
          </Column>
          <Column field="receivedAt" header="Received At">
            <template #body="{ data }">
              {{ new Date(data["receivedAt"]).toLocaleString() }}
            </template>
          </Column>
          <!-- Show message in the middle if no devices -->
          <template v-if="filteredMessages.length === 0">
            <div
              class="empty-message"
              style="
                position: absolute;
                top: 72%;
                left: 50%;
                transform: translate(-50%, -72%);
              "
            >
              <p class="text-gray-500 dark:text-gray-400">
                No messages available.
              </p>
            </div>
          </template>
        </DataTable>
      </div>
    </div>
    <!-- Pagination Controls -->

    <div class="flex flex-col items-center">
      <!-- Help text -->
      <span class="text-sm text-gray-700 dark:text-gray-400">
        Showing
        <span class="font-semibold text-gray-900 dark:text-white"
          >(Page {{ this.currentPage }})
          {{ this.currentPage * this.pageSize - this.pageSize + 1 }}</span
        >
        to
        <span class="font-semibold text-gray-900 dark:text-white">{{
          this.currentPage * this.pageSize - this.pageSize + 10
        }}</span>
        of
        <span class="font-semibold text-gray-900 dark:text-white">{{
          this.totalMessagesCount
        }}</span>
        Entries
      </span>
      <!-- Buttons -->
      <div class="inline-flex mt-2 xs:mt-0">
        <button
          @click="goToPreviousPage"
          :disabled="currentPage === 1"
          class="flex items-center justify-center px-3 h-8 text-sm font-medium text-white bg-gray-800 rounded-s hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
        >
          Prev
        </button>
        <button
          @click="goToNextPage"
          :disabled="currentPage === totalPages"
          class="flex items-center justify-center px-3 h-8 text-sm font-medium text-white bg-gray-800 border-0 border-s border-gray-700 rounded-e hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import store, { getMessagesQuery } from "@/store/store";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import getDevices from "@/api/data";
import Preloader from "@/components/Preloader.vue";

export default {
  components: {
    Preloader,
    DataTable,
    Column,
  },
  data() {
    return {
      loading: true,
      userId: null,
      userType: null,
      deviceIds: [],
      messages: [],
      filteredMessages: [],
      devices: [],
      severities: [
        {
          name: "Critical",
          id: "Critical",
        },
        {
          name: "Medium",
          id: "Medium",
        },
        {
          name: "Low",
          id: "Low",
        },
        {
          name: "Info",
          id: "Info",
        },
      ],
      types: [
        {
          name: "IDS Messages",
          id: "IDS",
        },
        {
          name: "Heartbeat Messages",
          id: "Heartbeat",
        },
        {
          name: "System Messages",
          id: "System",
        },
        {
          name: "Software Messages",
          id: "SW",
        },
        {
          name: "BMS Messages",
          id: "BMS",
        },
        {
          name: "Meter Messages",
          id: "Meter",
        },
        {
          name: "Inverter Messages",
          id: "Inverter",
        },
      ],
      filters: {
        device: "",
        severity: "",
        type: "IDS",
      },
      currentPage: 1,
      pageSize: 10,
      totalPages: 0,
      totalMessagesCount: 0,
    };
  },
  mounted() {
    if (store.getters.getUser) {
      let user = store.getters.getUser;
      this.userId = user.id;
      this.userType = user.type;
      this.getDevices(this.userId, this.userType);
    }
  },
  methods: {
    getMessagesLength() {
      this.loading = true; // Start loading
      let deviceId = this.filters.device;
      let type = this.filters.type;

      this.$apollo
        .query({
          query: getMessagesQuery.getMessagesLength,
          variables: {
            deviceId: deviceId,
            messageType: type,
          },
        })
        .then(async (data) => {
          console.log("messages length => ", data.data.messagesLength.length)
          let totalMessages = data.data.messagesLength.length;
          this.totalMessagesCount = totalMessages;
          this.totalPages = Math.ceil(totalMessages / this.pageSize);
          this.getFilteredMessages();
        })
        .catch((error) => {
          // Error
          console.error("Error in Messages get messages => ", error);
        });
      this.loading = false; // Stop loading on error
    },
    getFilteredMessages() {
      this.loading = true; // Start loading
      let deviceId = this.filters.device;
      let type = this.filters.type;
      let offset = this.currentPage * this.pageSize - this.pageSize;
      let first = this.pageSize;

      this.$apollo
        .query({
          query: getMessagesQuery.getPaginatedMessagesFiltered,
          variables: {
            deviceId: deviceId,
            messageType: type,
            first: this.pageSize,
            offset: this.currentPage * this.pageSize - this.pageSize,
          },
        })
        .then(async (data) => {
          this.filteredMessages = data.data.messages.messages;
          let arrayForSort = [...this.filteredMessages];
          let filteredBySeverity = arrayForSort.filter((message) => {
            return (
              message.severity &&
              (this.filters.severity === "" ||
                message.severity === this.filters.severity)
            );
          });

          this.filteredMessages = filteredBySeverity;
        })
        .catch((error) => {
          // Error
          console.error("Error in Messages get messages => ", error);
          this.loading = false; // Stop loading
        });
      this.loading = false; // Stop loading
    },
    filterMessages() {
      this.currentPage = 1;
      this.getMessagesLength();
    },
    async getDevices(userId, userType) {
      const devicesData = await getDevices(
        this.$apollo,
        this.$router,
        userId,
        userType
      );
      if (devicesData.length > 0) {
        devicesData.forEach((device) => {
          this.devices.push(device);
        });

        // Sort devices by Received At field
        this.devices.sort((a, b) => {
          return new Date(b.receivedAt) - new Date(a.receivedAt);
        });

        this.filteredMessages = this.messages;
        this.filters.device = this.devices[0].id;
        this.getMessagesLength();
      }
    },
    goToFirstPage() {
      this.currentPage = 1;
      this.getFilteredMessages();
    },
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getFilteredMessages();
      }
    },
    goToNextPage() {
      this.currentPage++;
      this.getFilteredMessages();
    },
    goToLastPage() {
      this.currentPage = this.totalPages;
      this.getFilteredMessages();
    },
    goToPage(pageNumber) {
      this.currentPage = pageNumber;
      this.getFilteredMessages();
    },
  },
};
</script>

<style scoped>
/*::v-deep selector allows you to target nested components and apply styles to them, even when the styles are scoped */
::v-deep .p-datatable-table {
  border-spacing: 0px;
  width: 100%;
  border-collapse: collapse;
  overflow: auto !important;
  margin: 25px 0;
  min-width: 400px;
  overflow-x: scroll !important;
}

::v-deep .p-datatable .p-datatable-thead > tr > th,
::v-deep .p-datatable .p-datatable-tbody > tr > td {
  text-align: center !important;
  text-wrap: balance;
}

::v-deep .p-datatable .p-column-header-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep tr {
  background-color: #f8f9fa;
}

::v-deep th,
::v-deep td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

::v-deep th {
  padding: 10px 0.55rem;
}

::v-deep .p-datatable .p-datatable-tbody > tr > td {
  padding-inline: 0.4rem;
}

::v-deep .column-health {
  width: 50%;
}

.space-between {
  justify-content: space-between;
  align-items: center;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.pagination-controls button {
  margin: 0 0.5rem;
}
</style>
