<template>
  <!-- this header -->
  <header class="bg-white dark:bg-gray-800 p-2 border-b-2 dark:border-gray-700">
    <div class="wrap-header flex items-center gap-5 justify-between flex-wrap">
      <div class="flex flex-no-shrink items-center">
        <button
            class="text-gray-500 lg:hidden ml-3 block"
            @click="$emit('sidebarToggle', true)"
        >
          <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
              width="2em"
              height="2em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 16 16"
          >
            <path
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                d="M2.75 12.25h10.5m-10.5-4h10.5m-10.5-4h10.5"
            />
          </svg>
        </button>
        <div
            class="input-box border dark:bg-gray-900 lg:ml-0 ml-5 dark:border-gray-700 rounded-md hidden lg:w-search w-full box-border lg:flex md:flex focus-within:bg-gray-100 dark:focus-within:bg-gray-700"
        >
          <span class="text-3xl p-2 text-gray-400"
          ><Icon icon="ei:search"
          /></span>
          <input
              type="text"
              placeholder="Search..."
              class="p-3 w-full bg-white dark:bg-gray-900 dark:text-gray-400 rounded-md outline-none focus:bg-gray-100 dark:focus:bg-gray-700"
          />
        </div>
      </div>

      <div class="mr-5 flex gap-3">
        <!-- btn dark mode -->

        <button class="lg:hidden block mr-5 text-2xl text-gray-500 relative">
          <i>
            <Icon icon="ic:outline-search"/>
          </i>
        </button>

        <button
            @click="fullscreenToggle"
            class="mr-5 text-2xl text-gray-500 relative"
        >
          <i v-if="!fullscreenMode">
            <Icon icon="ic:outline-fullscreen"/>
          </i>
          <i v-else>
            <Icon icon="ic:outline-fullscreen-exit"/>
          </i>
        </button>

        <button @blur="menuToggleBlur" @click="menuToggle">
          <div class="user-avatar flex p-1 cursor-pointer rounded-md">
            <div>
              <img
                  src="../assets/img/user.jpg"
                  class="rounded-full mr-4 w-10 h-10 p-1 ring-1 ring-gray-300 dark:ring-gray-500"
                  alt=""
              />
            </div>
            <div class="text-left lg:block md:block hidden">
              <h2 class="dark:text-white text-gray-800" v-if="user">
                Hi, {{ user.first_name }}
              </h2>
              <!--Change the name of the user loged-->
              <p class="text-xs text-gray-400 dark:text-gray-500" v-if="user.type == 'operator'" >
                Operator
              </p>
              <p class="text-xs text-gray-400 dark:text-gray-500" v-else >

              </p>
            </div>
          </div>
        </button>

        <transition name="fade">
          <div
              id="dropdownSmall"
              v-show="menu"
              class="block absolute right-10 mt-12 z-50 w-52 border dark:border-gray-700 bg-white dark:bg-gray-800 rounded divide-y dark:divide-gray-700 divide-gray-100 shadow"
          >
            <div class="py-3 px-4 text-sm text-gray-900 dark:text-gray-200">
              <div>Logged As</div>
              <div class="font-medium truncate" v-if="user">
                {{ user.first_name + " " + user.surname }}
              </div>
              <!--Change the name of the user loged-->
            </div>
            <ul
                class="py-1 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownSmallButton"
            >
              <li>
                <router-link
                    to="/component/settings"
                    class="block py-2 px-4 hover:bg-primary hover:text-white"
                >User Profile
                </router-link
                >
              </li>
            </ul>

            <div class="py-1 logout">
              <a
                  @click="logout"
                  class="block py-2 px-4 text-sm text-gray-700 dark:text-gray-200 hover:bg-primary hover:text-white"
              >Sign out</a
              >
            </div>
          </div>
        </transition>
      </div>
    </div>
  </header>
</template>

<script>
import {Icon} from "@iconify/vue";
import {fullscreen} from "@/helper/fullscreen";
import {setDarkMode, loadDarkMode} from "@/helper/theme";
import store, {refreshToken} from "@/store/store";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import logout from "@/jwt/auth";

export default {
  data() {
    return {
      error: null,

      menu: false,
      darkMode: false,
      notification: false,
      fullscreenMode: false,
    };
  },
  computed: {
    user() {
      return store.getters.getUser;
    },
  },
  created() {
  },
  components: {
    Icon,
  },
  watch: {
    $route() {
      this.menu = false;
      this.notification = false;
    },
  },
  methods: {
    fullscreen,
    setDarkMode,
    loadDarkMode,

    menuToggle: function () {
      this.menu = !this.menu;
    },
    menuToggleBlur: function () {
      this.menu = false;
    },
    fullscreenToggle() {
      this.fullscreenMode = !this.fullscreenMode;
      this.fullscreen(this.fullscreenMode);
    },
    logout() {
      logout(this.$router);
    },
  },
  mounted() {
    // get theme dark or light with loadDarkMode()
    this.darkMode = this.loadDarkMode();
    document.onfullscreenchange = (event) => {
      if (document.fullscreenElement) {
        this.fullscreenMode = true;
      } else {
        this.fullscreenMode = false;
      }
    };
  },
};
</script>
<style scoped>
::v-deep .logout a {
  cursor: pointer
}

::v-deep .z-50 {
  z-index: 1000;
}
</style>
