import checkToken from "@/jwt";
import {getDevicesByIdQuery, getMessagesQuery} from "@/store/store";

export async function getDevicesById(apollo, router, id) {
    try {
        await checkToken(apollo, router);

        const {data} = await apollo.query({
            query: getDevicesByIdQuery.getDevicesByIdQuery,
            variables: {id}
        });

        const devicesData = data.devices.devices;
        if (devicesData.length > 0) {
            return devicesData[0];
        }
        return null;
    } catch (error) {
        console.error('Error in getDevicesById query =>', error);
        return null;
    }
}

export async function getMessagesByDeviceIdAndTimeRange(apollo, router, deviceId,begin,end) {
    try {
        await checkToken(apollo, router);

        const {data} = await apollo.query({
            query: getMessagesQuery.getMessagesByDeviceIdAndTimeRange,
            variables: {
                deviceId: deviceId,
                begin: begin,
                end: end,
            }
        });

        if (data?.messages?.messages) {
            const messages = data.messages.messages;
            return messages;
        }
        return null;
    } catch (error) {
        console.error('Error in getDevicesById query =>', error);
        return null;
    }
}

