<template>
  <div class="flex flex-col items-center justify-center p-5">
    <notifications group="foo" />
    <form
      @submit.prevent="updateProfile"
      class="w-full sm:w-3/4 md:w-2/3 lg:w-1/2"
    >
      <div
        class="mb-4 flex flex-col sm:flex-row items-center sm:justify-start sm:space-x-4"
      >
        <label
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3 mb-2 sm:mb-0 sm:mr-2 w-[150px]"
          for="firstname"
          >First Name</label
        >
        <input
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3"
          id="firstname"
          v-model="user.first_name"
          type="text"
        />
      </div>

      <div
        class="mb-4 flex flex-col sm:flex-row items-center sm:justify-start sm:space-x-4"
      >
        <label
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3 mb-2 sm:mb-0 sm:mr-2 w-[150px]"
          for="surname"
          >Surname</label
        >
        <input
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3"
          id="surname"
          v-model="user.surname"
          type="text"
        />
      </div>
      <div
        class="mb-4 flex flex-col sm:flex-row items-center sm:justify-start sm:space-x-4"
      >
        <label
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3 mb-2 sm:mb-0 sm:mr-2 w-[150px]"
          for="email"
          >Email</label
        >
        <input
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3"
          id="email"
          v-model="user.email"
          type="email"
        />
      </div>
      <div class="flex justify-center mt-6 mr-48">
        <button
          class="bg-[#318bc8] hover:bg-[#318bc8] text-white font-bold py-2 px-4 rounded"
          type="submit"
        >
          Update
        </button>
      </div>
    </form>
    <div v-if="updated" class="mt-3 text-green-500">
      Your profile has been updated.
    </div>
  </div>
</template>

<script>
import store from "@/store/store";
export default {
  name: "ProfileComponent",
  data() {
    return {
      updated: false,
    };
  },

  computed: {
    user() {
      return store.getters.getUser;
    },
  },
  methods: {
    updateProfile() {
      try {
        localStorage.setItem("profile", JSON.stringify(this.profile));
        this.$notify({
          group: "foo",
          type: "success",
          title: "Success",
          text: "Your profile has been updated successfully.",
        });
        this.updated = true;
      } catch (e) {
        this.$notify({
          group: "foo",
          type: "error",
          title: "Error",
          text: "An error occurred while updating your profile.",
        });
        this.updated = false;
      }
    },
  },
};
</script>
