import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client/core";
import { setContext } from "apollo-link-context";
import { createApolloProvider } from "@vue/apollo-option";

const cache = new InMemoryCache();
const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  let token = JSON.parse(sessionStorage.getItem("apollo-token"));
  if (!token) {
    console.warn("No Token Retrieved");
  }
  // Return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null,
    },
  };
});

const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: "https://trident-api.tisalabs.com/api/",
});

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});

export const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
});

export default apolloClient;
