<template>
  <div class="w-full">
    <div class="flex shadow bg-waves rounded-md">
      <div class="bg-white dark:bg-gray-900 w-full">
        <form @submit.prevent="register">
          <div
            class="form-body max-w-xl mx-auto lg:p-20 p-8 lg:mt0 mt-0 space-y-5"
          >
            <div class="form-head cursor-pointer" @click="$router.push('/')">
              <span
                class="dark:text-white font-semibold text-gray-800 text-4xl flex"
              >
                <img src="../../../assets/logo/logo.svg" alt="" class="w-10" />
                <span class="trident_title">Trident</span>
              </span>
            </div>
            <div class="space-y-3">
              <h2 class="dark:text-white font-semibold text-gray-800 text-4xl">
                Create new account?
              </h2>
              <p class="dark:text-gray-400 text-gray-700">
                Please enter your information to register account.
              </p>
            </div>
            <button
              type="button"
              class="dark:text-white text-gray-700 flex justify-center gap-2 dark:bg-gray-700 bg-gray-100 hover:bg-gray-100/50 p-2 w-full rounded-md"
              @click="googleLogin"
            >
              <img
                class="w-8"
                src="../../../assets/logo/google-logo.svg"
                alt=""
              />
              <p class="mt-1 dark:text-white text-gray-700">
                Sign up with Google
              </p>
            </button>
            <span class="flex items-center justify-center space-x-2">
              <span class="h-px dark:bg-gray-600 bg-gray-200 w-full"></span>
              <span class="font-normal text-gray-500">or </span>
              <span class="h-px dark:bg-gray-600 bg-gray-200 w-full"></span>
            </span>
            <div class="space-y-5">
              <div class="relative z-0 w-full mb-6 group">
                <input
                  type="text"
                  v-model="first_name"
                  name="floating_name"
                  id="floating_name"
                  class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-primary focus:outline-none focus:ring-0 focus:border-primary peer"
                  placeholder=" "
                  required
                />
                <label
                  for="floating_name"
                  class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >First Name</label
                >
              </div>
              <div class="relative z-0 w-full mb-6 group">
                <input
                  type="text"
                  v-model="surname"
                  name="floating_surname"
                  id="floating_surname"
                  class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-primary focus:outline-none focus:ring-0 focus:border-primary peer"
                  placeholder=" "
                  required
                />
                <label
                  for="floating_name"
                  class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >Surname</label
                >
              </div>
              <div class="relative z-0 w-full mb-6 group">
                <input
                  type="email"
                  v-model="email"
                  name="floating_email"
                  id="floating_email"
                  class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-primary focus:outline-none focus:ring-0 focus:border-primary peer"
                  placeholder=" "
                  required
                />
                <label
                  for="floating_email"
                  class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >Email address</label
                >
              </div>
              <div class="relative z-0 w-full mb-6 group">
                <input
                  type="password"
                  v-model="password"
                  name="floating_password"
                  id="floating_password"
                  class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-primary focus:outline-none focus:ring-0 focus:border-primary peer"
                  placeholder=" "
                  required
                />
                <label
                  for="floating_password"
                  class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >Password</label
                >
              </div>
              <div class="relative z-0 w-full mb-6 group">
                <input
                  type="password"
                  v-model="confirm_password"
                  name="floating_confirm_password"
                  id="floating_confirm_password"
                  class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-primary focus:outline-none focus:ring-0 focus:border-primary peer"
                  placeholder=" "
                  required
                />
                <label
                  for="floating_confirm_password"
                  class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >Confirm Password</label
                >
              </div>
              <div class="relative z-0 w-full mb-6 group">
                <input
                  type="text"
                  v-model="company_name"
                  name="floating_company_name"
                  id="floating_company_name"
                  class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-primary focus:outline-none focus:ring-0 focus:border-primary peer"
                  placeholder=" "
                  required
                />
                <label
                  for="floating_company_name"
                  class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >Company</label
                >
              </div>
              <div class="relative z-0 w-full mb-6 group">
                <input
                  type="text"
                  v-model="adresse_1"
                  name="floating_adresse_1"
                  id="floating_adresse_1"
                  class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-primary focus:outline-none focus:ring-0 focus:border-primary peer"
                  placeholder=" "
                  required
                />
                <label
                  for="floating_adresse_1"
                  class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >Adresse Line 1</label
                >
              </div>
              <div class="relative z-0 w-full mb-6 group">
                <input
                  type="text"
                  v-model="adresse_2"
                  name="floating_adresse_2"
                  id="floating_adresse_2"
                  class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-primary focus:outline-none focus:ring-0 focus:border-primary peer"
                  placeholder=" "
                  required
                />
                <label
                  for="floating_adresse_2"
                  class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >Adresse Line 2</label
                >
              </div>
              <div class="relative z-0 w-full mb-6 group">
                <input
                  type="text"
                  v-model="city"
                  name="floating_city"
                  id="floating_city"
                  class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-primary focus:outline-none focus:ring-0 focus:border-primary peer"
                  placeholder=" "
                  required
                />
                <label
                  for="floating_city"
                  class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >City</label
                >
              </div>
              <div class="relative z-0 w-full mb-6 group">
                <input
                  type="text"
                  v-model="state"
                  name="floating_state"
                  id="floating_state"
                  class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-primary focus:outline-none focus:ring-0 focus:border-primary peer"
                  placeholder=" "
                  required
                />
                <label
                  for="floating_state"
                  class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >Country/State</label
                >
              </div>
              <div class="relative z-0 w-full mb-6 group">
                <input
                  type="text"
                  v-model="post_code"
                  name="floating_post_code"
                  id="floating_post_code"
                  class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-primary focus:outline-none focus:ring-0 focus:border-primary peer"
                  placeholder=" "
                  required
                />
                <label
                  for="floating_post_code"
                  class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >PostCode</label
                >
              </div>
              <div class="relative z-0 w-full mb-6 group">
                <input
                  type="text"
                  v-model="country"
                  name="floating_country"
                  id="floating_country"
                  class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-primary focus:outline-none focus:ring-0 focus:border-primary peer"
                  placeholder=" "
                  required
                />
                <label
                  for="floating_country"
                  class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >Country</label
                >
              </div>
              <div class="">
                <vue-tel-input
                  v-model="phoneNumber"
                  v-bind="vueTel.props"
                  :dropdownOptions="{
                    showFlags: true,
                    showDialCodeInList: true,
                  }"
                ></vue-tel-input>
              </div>
              <div class="relative z-0 w-full mb-6 group">
                <input
                  type="text"
                  v-model="vat"
                  name="floating_vat"
                  id="floating_vat"
                  class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-primary focus:outline-none focus:ring-0 focus:border-primary peer"
                  placeholder=" "
                  required
                />
                <label
                  for="floating_vat"
                  class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >VAT</label
                >
              </div>
            </div>
            <div class="flex justify-between">
              <div class="flex items-start">
                <div class="flex items-center h-5">
                  <input
                    id="remember"
                    type="checkbox"
                    v-model="termsAccepted"
                    value=""
                    autocomplete="off"
                    class="accent-primary focus:ring-4 cursor-pointer w-4 h-4 border border-gray-300 rounded dark:bg-gray-700 bg-gray-50 focus:ring-3 focus:ring-primary/30"
                  />
                </div>
                <label
                  class="ml-2 text-sm cursor-pointer font-normal dark:text-white text-gray-500"
                  >I have read and agree to the
                  <router-link
                    class="text-primary"
                    :to="`/auth/terms`"
                    target="_blank"
                  >
                    terms of service
                  </router-link>
                </label>
              </div>
            </div>

            <button
              class="text-white bg-primary hover:bg-primary/80 p-3 w-full rounded-md"
            >
              Submit, and Register
            </button>
            <p class="dark:text-white text-center text-gray-700">
              Already have an account?<button
                type="button"
                @click="$router.push('/auth/login')"
                class="ml-2 text-primary"
              >
                Login here
              </button>
            </p>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import store, {
  graphqlMutations,
  getDevicesQueries,
  getUserQuery,
} from "@/store/store";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import "firebase/compat/firestore";
import db from "@/main";
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";

import gql from "graphql-tag";

export default {
  data() {
    return {
      passwordMatch: true,
      vueTel: {
        props: {
          preferredCountries: ["US", "GB"],
          placeholder: "Enter your phone",
          mode: "international",
          inputOptions: {
            showDialCode: true,
          },
          disabledFormatting: false,
          wrapperClasses: "country-phone-input",
        },
      },
      phoneNumber: null,
      termsAccepted: false,
    };
  },
  methods: {
    register() {
      this.checkPasswordsMatch();
      if (!this.termsAccepted) {
        toast.error(
          "You must accept the terms and conditions before registering",
          {
            position: toast.POSITION.BOTTOM_CENTER,
            theme: "colored",
          }
        );
      } else if (this.password !== this.confirm_password) {
        toast.error("Passwords do not match", {
          position: toast.POSITION.BOTTOM_CENTER,
          theme: "colored",
        });
        this.passwordsMatch = false;
        return;
      } else {
        var promise = firebase
          .auth()
          .createUserWithEmailAndPassword(this.email, this.password);

        promise
          .then(async (userCredential) => {
            userCredential.user
              .sendEmailVerification()
              .then(async (result) => {
                userCredential.user.getIdToken(true).then(async (token) => {
                  const { email } = userCredential.user;

                  const user = {
                    email: email,
                    first_name: this.first_name,
                    surname: this.surname,
                    company: this.company_name,
                    AdresseLine1: this.adresse_1,
                    AdresseLine2: this.adresse_2,
                    city: this.city,
                    state: this.state,
                    postCode: this.post_code,
                    country: this.country,
                    phone: this.phoneNumber,
                    vat: this.vat,
                    type: "operator",
                    enabled: false,
                  };

                  await this.generateTokenAndRegister(token, user);
                });
              })
              .catch((err) => {
                console.log("ERROR", err);
              });
          })
          .catch((error) => {
            console.log("Error creating user:", error);
            alert(error.message);
          });
      }
    },
    async generateTokenAndRegister(token, user) {
      this.$apollo
        .mutate({
          // Query
          mutation: graphqlMutations.generateTokenMutation,
          variables: {
            token: token,
          },
        })
        .then(async (data) => {
          const token = JSON.stringify(data.data.auth.accessToken);
          const refreshToken = JSON.stringify(data.data.auth.refreshToken);
          sessionStorage.setItem("apollo-token", token);
          sessionStorage.setItem("refreshToken", refreshToken);
          await this.createUser(user);
        })
        .catch((error) => {
          // Error
          console.error("Error in GenerateToken mutation => ", error);
        });
    },
    async createUser(user) {
      let variables = {
        email: user.email,
        enabled: user.enabled,
        firstName: user.first_name,
        surname: user.surname,
        type: user.type,
      };
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation AddUser(
              $email: String!
              $enabled: Boolean!
              $firstName: String!
              $surname: String!
              $type: String!
            ) {
              addUser(
                email: $email
                enabled: $enabled
                firstName: $firstName
                surname: $surname
                type: $type
              ) {
                response {
                  ... on ResponseField {
                    message
                    ok
                  }
                  ... on AuthInfoField {
                    message
                  }
                }
              }
            }
          `,
          variables: variables,
        })
        .then(async (data) => {
          this.logout();
        })
        .catch((error) => {
          // Error
          console.error("Error in AddUser mutation => ", error);
        });
    },
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          const user = {
            email: "",
            first_name: "",
            surname: "",
            type: "",
          };
          sessionStorage.clear();
          store.dispatch("setUser", user);
          sessionStorage.removeItem("apollo-token");
          this.$router.push("/");
        })
        .catch((error) => {
          alert("Error while logout", error);
        });
    },
    googleLogin() {
      let provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(async (result) => {
          const username = result.user.displayName;
          const splitname = username.split(" ");

          const user = {
            email: result.user.email,
            first_name: splitname[0],
            surname: splitname[1],
            type: "operator",
            enabled: true,
          };
          await this.getUser(user);
        })
        .catch((error) => {
          console.log("Error while login user with Google:", error);
          alert(error.message);
        });
    },
    async getUser(user) {
      this.$apollo
        .query({
          query: getUserQuery.getUsersQuery,
          variables: {
            email: user.email,
          },
        })
        .then(async (data) => {
          store.dispatch("setUser", user);
          if (data.data.users.users.length == 0) {
            await this.createUser(user);
            this.$router.push("/");
          } else {
            this.$router.push("/");
          }
        })
        .catch((error) => {
          // Error
          console.error("Error in login getUsers query => ", error);
        });
    },
    checkPasswordsMatch() {
      this.passwordMatch = this.password === this.confirm_password;
    },
  },
};
</script>
<style scoped>
::v-deep .vti__input {
  border: none;
  border-radius: 0;
  width: 100%;
  outline: none;
  padding-left: 0;
  background: inherit;
}

::v-deep .vue-tel-input {
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
  box-shadow: 0 0 0 black !important;
}

::v-deep .vue-tel-input:focus {
  box-shadow: 0 0 0 black;
}

::v-deep .vue-tel-input {
  display: flex;
  height: 100%;
}

::v-deep [data-v-913b32cc] .vti__input {
  border: none;
  border-radius: 0;
  width: 100%;
  outline: none;
  padding-left: 0;
  background: inherit;
  color: white;
}
</style>
