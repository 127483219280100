import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import store from "@/store/store";

export default function logout(router) {
  firebase
    .auth()
    .signOut()
    .then(() => {
      const user = {
        email: "",
        username: "",
        type: "",
        enabled: false,
      };
      sessionStorage.clear();

      store.dispatch("setUser", user);
      router.push("/auth/login");
    })
    .catch((error) => {
      console.error("Error while logout", error);
      router.push("/");
    });
}
