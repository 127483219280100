<template>
  <div class="preloader">
    <div class="gear">
      <img src="@/assets/img/spinner.png" alt="Loading Gear" class="gear-img" />
    </div>
  </div>
</template>

<script>
export default {
  name: "PreLoader",
};
</script>

<style scoped>
.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 67px;
  left: 250px; /* Default left position */
  width: calc(100% - 250px); /* Adjust width to account for the left offset */
  height: calc(100% - 67px); /* Adjust height to account for the top offset */
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent white background */
  z-index: 900; /* Ensure it overlays other content */
}
@media (max-width: 1024px) {
  .preloader {
    left: 0; /* Adjust left position for screens 1024px wide or smaller */
    width: 100%; /* Reset width to full for smaller screens */
  }
}
@media (max-width: 768px) {
  .preloader {
    left: 0; /* Adjust left position for small screens */
    width: 100%; /* Reset width to full for smaller screens */
  }
}

.gear {
  display: flex;
  width: 100px;
  height: 100px;
  position: relative;
}

.gear-img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: center;
  transform: translate(-50%, -50%);
  animation: gear-spin 2s linear infinite;
}

@keyframes gear-spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>
