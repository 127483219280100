<template>
  <div class="flex flex-col overflow-y-auto h-screen px-4">
    <h1 class="text-center text-3xl my-4 dark:text-white">Terms of Service</h1>
    <div class=" terms-of-service prose prose-lg">
      <h3
        class="text-center text-2xl text-gray-900 dark:text-gray-200 font-medium"
      >
        TISALABS END USER LICENSE AGREEMENT
      </h3>
      <span class="text-white p-3 max-w-sm flex ml-20 mb-2"
        >Last updated: 18/03/2020
      </span>
      <div class="block w-10/12 ml-20 w-full pl-3">
        <p class="font-semibold text-slate-400 text-sm font-light">
          Please read this End User License Agreement (“Agreement”) carefully
          before clicking the “I Agree” button, downloading or using any
          TisaLabs applications or websites. By clicking the “I Agree” button,
          downloading or using our services, you are agreeing to be bound by the
          terms and conditions of this Agreement. If you do not agree to the
          terms of this Agreement, click on the “I Decline” button instead and
          do not download or use our services.
        </p>
      </div>
      <span class="text-white p-3 max-w-sm flex ml-20 mb-2">License </span>
      <div class="block w-10/12 ml-20 w-full pl-3">
        <p class="font-semibold text-slate-400 text-sm font-light">
          TisaLabs grants you a revocable, non-exclusive, non-transferable,
          limited license to download, install and use our applications and
          services strictly in accordance with the terms of this Agreement.
        </p>
      </div>
      <span class="text-white p-3 max-w-sm flex ml-20 mb-2">Restrictions </span>
      <div class="block w-10/12 ml-20 w-full pl-3">
        <p class="font-semibold text-slate-400 text-sm font-light">
          You agree not to, and you will not permit others to:
        </p>
        <p class="font-semibold text-slate-400 text-sm font-light pt-2">
          a) license, sell, rent, lease, assign, distribute, transmit, host,
          outsource, disclose or otherwise commercially exploit the applications
          or services or make them available to any third party.
        </p>
      </div>
      <span class="text-white p-3 max-w-sm flex ml-20 mb-2"
        >Modifications to Applications / Services
      </span>
      <div class="block w-10/12 ml-20 w-full pl-3">
        <p class="font-semibold text-slate-400 text-sm font-light">
          TisaLabs reserves the right to modify, suspend or discontinue,
          temporarily or permanently, the application or any service to which it
          connects, with or without notice and without liability to you.
        </p>
      </div>
      <span class="text-white p-3 max-w-sm flex ml-20 mb-2"
        >Confidentiality
      </span>
      <div class="block w-10/12 ml-20 w-full pl-3">
        <p class="font-semibold text-slate-400 text-sm font-light">
          You acknowledge and agree that the applications and services are
          confidential information of TisaLabs and that they contain trade
          secrets which are prohibited from being made public to third parties.
          You agree to treat the software with confidentiality and not to allow
          use for a purpose other than the intended use. You will not disclose
          confidential information to anyone other than your employees,
          collaborators and contractors who have a need-to-know for purposes of
          using the software on your behalf or in connection with your projects.
        </p>
      </div>
      <span class="text-white p-3 max-w-sm flex ml-20 mb-2"
        >Intellectual Property Indemnification
      </span>
      <div class="block w-10/12 ml-20 w-full pl-3">
        <p class="font-semibold text-slate-400 text-sm font-light">
          TisaLabs will indemnify and hold you harmless from any third party
          claim brought against you that the software, as provided by TisaLabs
          to you under this Agreement and used within the scope of this
          Agreement, infringes or misappropriates any U.S. patent, copyright,
          trademark, trade secret, or other intellectual property rights of a
          third party, provided that the use of the software by you is in
          conformity with the Agreement and the infringement is not caused by
          modification or alteration of the software.
        </p>
      </div>
      <span class="text-white p-3 max-w-sm flex ml-20 mb-2"
        >Copy and Use Terms
      </span>
      <div class="block w-10/12 ml-20 w-full pl-3">
        <p class="font-semibold text-slate-400 text-sm font-light">
          The use of the software depends on the licenses purchased and the
          total number of devices on which all versions of the software is
          installed may not exceed your product entitlement. You may, however,
          copy the software as reasonably necessary for back-up, archival or
          disaster recovery purposes.
        </p>
        <p class="font-semibold text-slate-400 text-sm font-light">
          You agree not to, and you will not permit others to:
        </p>
        <p class="font-semibold text-slate-400 text-sm font-light pt-2">
          a) decompile, disassemble or reverse-engineer the software, create or
          recreate the source code for the software.
        </p>
        <p class="font-semibold text-slate-400 text-sm font-light pt-2">
          b) remove, erase, obscure, or tamper with any copyright or any other
          product identification of the software.
        </p>
        <p class="font-semibold text-slate-400 text-sm font-light pt-2">
          c) lease, lend or use the software for time-sharing or service bureau
          purposes; sell, market, license, sublicense, distribute, or otherwise
          grant to any person or entity any right to use the software except to
          the extent expressly permitted in this agreement
        </p>
      </div>
      <span class="text-white p-3 max-w-sm flex ml-20 mb-2"
        >Term and Termination
      </span>
      <div class="block w-10/12 ml-20 w-full pl-3">
        <p class="font-semibold text-slate-400 text-sm font-light">
          This Agreement shall remain in effect until terminated by you or
          TisaLabs. TisaLabs may, in its sole discretion, at any time and for
          any or no reason, suspend or terminate this Agreement with or without
          prior notice. This Agreement will terminate immediately, without prior
          notice from TisaLabs, in the event that you fail to comply with any
          provision of this Agreement. You may also terminate this Agreement by
          deleting the applications and all copies thereof from your mobile
          device or from your desktop. Upon termination of this Agreement, you
          shall cease all use of the applications and services and delete all
          copies of the applications and services from your mobile device or
          from your desktop.
        </p>
      </div>
      <span class="text-white p-3 max-w-sm flex ml-20 mb-2">Severability </span>
      <div class="block w-10/12 ml-20 w-full pl-3">
        <p class="font-semibold text-slate-400 text-sm font-light">
          If any provision of this Agreement is held to be unenforceable or
          invalid, such provision will be changed and interpreted to accomplish
          the objectives of such provision to the greatest extent possible under
          applicable law and the remaining provisions will continue in full
          force and effect.
        </p>
      </div>
      <span class="text-white p-3 max-w-sm flex ml-20 mb-2"
        >Amendments to this Agreement
      </span>
      <div class="block w-10/12 ml-20 w-full pl-3">
        <p class="font-semibold text-slate-400 text-sm font-light">
          TisaLabs reserves the right, at its sole discretion, to modify or
          replace this Agreement at any time. If a revision is material we will
          provide at least 30 (changes this) days’ notice prior to any new terms
          taking effect. What constitutes a material change will be determined
          at our sole discretion.
        </p>
      </div>
      <span class="text-white p-3 max-w-sm flex ml-20 mb-2"
        >Contact Information
      </span>
      <div class="block w-10/12 ml-20 w-full pl-3 pb-3">
        <p class="font-semibold text-slate-400 text-sm font-light">
          If you have any questions about this Agreement, please contact us or
          take a look at the full End User License Agreement:
        </p>
        <p class="font-semibold text-slate-400 text-sm font-light pt-2">
          The Rubicon Centre,
        </p>
        <p class="font-semibold text-slate-400 text-sm font-light pt-2">
          CIT Campus, Bishopstown, Cork, T12 Y275, Ireland.
        </p>
        <p class="font-semibold text-slate-400 text-sm font-light pt-2">
          info@tisalabs.com
        </p>
        <p class="font-semibold text-slate-400 text-sm font-light pt-2">
          +353 21 202 1103
        </p>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer";
export default {
  components:{
    Footer
  }
};
</script>

<style scoped>
.terms-of-service{
  width: 90%;
  margin-inline: auto;
  padding-inline: 1rem;
  margin-left: 0;
  padding-left: 0;
}
</style>
