const mutations = {
    setUser(state, payload) {
      state.user.id = payload.id;
      state.user.first_name = payload.first_name;
      state.user.surname = payload.surname;
      state.user.email = payload.email;
      state.user.type = payload.type;
      state.user.enabled = payload.enabled;
    },
    setUserGoogle(state, payload) {
      const username = payload.displayName;
      const splitname = username.split(" ");
      state.user.first_name = splitname[0];
      state.user.surname = splitname[1];
      state.user.email = payload.email;
      state.user.type = "operator";
    },
    setDevices(state, payload) {
      state.devices = payload;
    },
    setRememberedEmail(state, payload) {
      state.rememberedEmail = payload.email;
      state.rememberedEmailExpiration = payload.expiration;
    },
    // Mutation to set deviceDetailsInfo
    setDeviceDetailsInfo(state, device) {
      state.deviceDetailsInfo = device;
    },
  };
  
  export default mutations;