<template>
  <Preloader v-if="loading" />

  <div >
    <div class="flex items-center justify-between mt-6 w-full">
      <h1
        class="text-2xl text-gray-900 dark:text-gray-200 font-medium py-3 px-4 min-w-[150px]"
      >
        Devices
      </h1>
    </div>
    <div class="flex items-end justify-end p-4 pb-0 w-full">
      <button
        @click="openModal"
        class="bg-[#318bc8] hover:bg-[#318bc8] text-white font-bold py-2 px-4 rounded"
      >
        + Create Device
      </button>

      <transition name="modal">
        <div
          v-if="isModalOpen"
          class="fixed inset-0 flex items-center justify-center z-50"
        >
          <!-- Overlay with @click to close modal -->
          <div
            @click="closeModal"
            class="fixed inset-0 z-40 bg-black bg-opacity-50"
          ></div>

          <div class="z-50 bg-white rounded p-8 max-w-md w-full text-center">
            <!-- Form -->
            <form @submit.prevent="submitForm">
              <div class="mb-4 flex items-center custom--grid--layout">
                <label
                  for="deviceName"
                  class="block text-sm font-medium text-gray-700"
                  >Name</label
                >
                <input
                  type="text"
                  id="deviceName"
                  v-model="formData.deviceName"
                  placeholder="Device Name"
                  class="mt-1 p-2 border rounded-md w-full"
                />
              </div>
              <div class="mb-4 flex items-center custom--grid--layout">
                <label
                  for="deviceModel"
                  class="block text-sm font-medium text-gray-700"
                  >Model</label
                >
                <input
                  type="text"
                  id="deviceModel"
                  v-model="formData.deviceModel"
                  placeholder="Device Model"
                  class="mt-1 p-2 border rounded-md w-full"
                />
              </div>
              <div class="mb-4 flex items-center custom--grid--layout">
                <label
                  for="manufacturer"
                  class="block text-sm font-medium text-gray-700"
                  >Manufacturer</label
                >
                <input
                  type="text"
                  id="manufacturer"
                  v-model="formData.manufacturer"
                  placeholder="Device Manufacturer"
                  class="mt-1 p-2 border rounded-md w-full"
                />
              </div>
              <div class="mb-4 flex items-center custom--grid--layout">
                <label
                  for="inverter"
                  class="block text-sm font-medium text-gray-700"
                  >Inverter</label
                >
                <select
                  id="inverter"
                  v-model="formData.inverter"
                  class="mt-1 p-2 border rounded-md w-full text-sm font-medium text-gray-700 ml-4"
                >
                  <option value="" disabled selected>Select Inverter</option>
                  <option
                    v-for="(inverter, index) in inverters"
                    :key="index"
                    :value="inverter"
                  >
                    {{ inverter }}
                  </option>
                </select>
              </div>
              <div class="mb-4 flex items-center custom--grid--layout">
                <label
                  for="meter"
                  class="block text-sm font-medium text-gray-700"
                  >Meter</label
                >
                <select
                  id="meter"
                  v-model="formData.meter"
                  class="mt-1 p-2 border rounded-md w-full text-sm font-medium text-gray-700 ml-4"
                >
                  <option value="" disabled selected>Select Meter</option>
                  <option
                    v-for="(meter, index) in meters"
                    :key="index"
                    :value="meter"
                  >
                    {{ meter }}
                  </option>
                </select>
              </div>
              <div class="mb-4 flex items-center custom--grid--layout">
                <label for="bms" class="block text-sm font-medium text-gray-700"
                  >BMS</label
                >
                <select
                  id="bms"
                  v-model="formData.bms"
                  class="mt-1 p-2 border rounded-md w-full text-sm font-medium text-gray-700 ml-4"
                >
                  <option value="" disabled selected>Select BMS</option>
                  <option
                    v-for="(bms, index) in bmss"
                    :key="index"
                    :value="bms"
                  >
                    {{ bms }}
                  </option>
                </select>
              </div>
              <div class="flex justify-middle mt-4 modal--btns">
                <button
                  type="submit"
                  class="bg-[#318bc8] hover:bg-[#318bc8] text-white font-bold py-2 px-4 rounded mr-3"
                >
                  create
                </button>
                <button
                  @click="closeModal"
                  class="bg-[#CB1515] hover:bg-[#CB1515] text-white font-bold py-2 px-4 rounded"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </transition>
    </div>
    <div
      class="card bg-white dark:bg-gray-800 rounded-md p-5 border dark:border-gray-700 flex mt-6 mb-6 mx-6 px-4"
    >
      <div class="flex flex-col w-full">
        <DataTable
          :value="devices"
          :rowStyle="rowStyle"
          :rowClass="rowClasses"
          tableStyle="min-width: 50rem"
          class="my-table"
        >
          <Column>
            <template #header="mainHeader">
              <div class="cell">
                <tr class="header">
                  <td>
                    <div>Trident Device</div>
                  </td>
                </tr>
                <tr class="mainHeader">
                  <td class="border-right">Device Name</td>
                  <td>Device ID</td>
                </tr>
              </div>
            </template>
            <template #body="slotProps">
              <div>
                <tr class="custom--grid">
                  <span
                    class="border-right pl-3 pr-2"
                    @click="navigate(slotProps.data)"
                  >
                    {{ slotProps.data.deviceName }}
                  </span>
                  <span class="pl-2">
                    {{ slotProps.data.deviceId }}
                  </span>
                </tr>
              </div>
            </template>
          </Column>

          <Column>
            <template #header="mainHeader">
              <div class="cell">
                <tr class="header">
                  <td>
                    <div>Inverter Module</div>
                  </td>
                </tr>
                <tr class="mainHeader">
                  <td class="border-right">
                    <div>Manufacturer</div>
                  </td>
                  <td>
                    <div>Hardware Version</div>
                  </td>
                </tr>
              </div>
            </template>
            <template #body="slotProps">
              <div>
                <tr>
                  <span class="border-right pl-4 pr-2">
                    {{ slotProps.data.manufacturer }}
                  </span>

                  <span class="pl-2">
                    {{ slotProps.data.hardwareVersion }}
                  </span>
                </tr>
              </div>
            </template>
          </Column>

          <Column>
            <template #header="mainHeader">
              <div class="cell">
                <tr class="header">
                  <td>
                    <div>Meter Module</div>
                  </td>
                </tr>
                <tr class="mainHeader">
                  <td class="border-right">
                    <div>Inverter Model</div>
                  </td>
                  <td>
                    <div>Software Version</div>
                  </td>
                </tr>
              </div>
            </template>
            <template #body="slotProps">
              <div>
                <tr>
                  <span class="border-right pl-1 pr-2">
                    {{ slotProps.data.inverter.model }}
                  </span>
                  <span class="pl-2">
                    {{ slotProps.data.meter.softwareVersion }}
                  </span>
                </tr>
              </div>
            </template>
          </Column>

          <Column>
            <template #header="mainHeader">
              <div class="cell">
                <tr class="header">
                  <td>
                    <div>BMS Module</div>
                  </td>
                </tr>
                <tr class="mainHeader">
                  <td class="border-right">
                    <div>Meter Model</div>
                  </td>
                  <td>
                    <div>BMS Model</div>
                  </td>
                </tr>
              </div>
            </template>
            <template #body="slotProps">
              <div>
                <tr>
                  <span class="border-right pl-3 pr-2">
                    {{ slotProps.data.meter.model }}
                  </span>
                  <span class="pl-2">
                    {{ slotProps.data.bms.model }}
                  </span>
                </tr>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Preloader from "@/components/Preloader.vue";

export default {
  components: {
    Preloader,
    DataTable,
    Column,
  },
  data() {
    return {
      loading: true,
      isModalOpen: false,
      formData: {
        deviceName: "",
        deviceModel: "",
        manufacturer: "",
        inverter: "",
        meter: "",
        bms: "",
      },
      inverters: ["Inverter 1", "Inverter 2", "Inverter 3"], // Sample array of inverters
      meters: ["Meter 1", "Meter 2", "Meter 3"], // Sample array of meters
      bmss: ["BMS 1", "BMS 2", "BMS 3"], // Sample array of BMSs
      devices: [
        {
          deviceName: "Device 1",
          deviceId: "ID 1",
          manufacturer: "Manufacturer 1",
          hardwareVersion: "Version 1",
          inverter: {
            model: "Inverter Model 1",
          },
          meter: {
            model: "Meter Model 1",
            softwareVersion: "Meter Version 1",
          },
          bms: {
            model: "BMS Model 1",
          },
        },
        {
          deviceName: "Device 2",
          deviceId: "ID 2",
          manufacturer: "Manufacturer 2",
          hardwareVersion: "Version 2",
          inverter: {
            model: "Inverter Model 2",
          },
          meter: {
            model: "Meter Model 2",
            softwareVersion: "Meter Version 2",
          },
          bms: {
            model: "BMS Model 2",
          },
        },
        // Add more Trident Devices as needed
      ],
    };
  },
  methods: {
    openModal() {
      document.body.classList.add("overflow-hidden");
      this.isModalOpen = true;
    },
    closeModal() {
      document.body.classList.remove("overflow-hidden");
      this.isModalOpen = false;
    },
    importData() {
      // Add logic to handle data import
      this.closeModal();
    },
    submitForm() {
      // Store the form data in a global variable or handle it as required
      const newDevice = {
        deviceName: this.formData.deviceName,
        deviceModel: this.formData.deviceModel,
        manufacturer: this.formData.manufacturer,
        inverter: this.formData.inverter,
        meter: this.formData.meter,
        bms: this.formData.bms,
      };
      // For example:
      // devices.push(newDevice);
      // Clear form data
      this.formData = {
        deviceName: "",
        deviceModel: "",
        manufacturer: "",
        inverter: "",
        meter: "",
        bms: "",
      };
      // Close the modal after form submission
      this.closeModal();
    },
  },
  mounted() {
    this.loading = true;
    //Your code of retrieving data
    this.loading = false;
  },
};
</script>

<style scoped>
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}
.modal-enter,
.modal-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
.modal--btns {
  justify-content: center;
}
.main-subheaders,
.mainHeader {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 0.5rem;
}

.cell {
  display: flex;
  flex-direction: column;
}
.cell .header {
  text-align: center;
}
.cell .header td {
  text-align: center;
  max-width: initial !important;
  width: 300px;
  /* outline: 1px solid; */
}
.my-table {
  border-collapse: collapse;
  width: 100%;
}
.my-table td {
  padding: 10px;
}
.mainHeader td {
  text-align: center;
  font-size: 0.8em;
  padding: 0;
}

.mainHeader {
  margin-inline: 0 !important;
  padding-inline: 0 !important;
}
.custom--grid--layout {
  display: grid;
  grid-template-columns: 20% auto;
}
label {
  text-align: left;
}
input {
  margin-left: 1rem;
}
.my-table /deep/ th {
  /*border:1px solid rgb(53, 51, 51) !important;*/
  padding: 0 !important;
}
.my-table /deep/ .p-datatable-tbody tr td {
  /*border:1px solid rgb(53, 51, 51) !important;*/
  padding: 0;
}
.border-right {
  /*border-right: 1px solid !important;*/
}
.custom--grid {
  width: 100% !important;
  padding: 0 1rem !important;
  display: grid;
  grid-template-columns: 50% 50%;
}
</style>
