<template>
  <div v-if="loading" class="preloader">
    <Preloader/>
  </div>
  <div v-else class="alerts__container mt-6 mb-6 mx-6 px-4">
    <!-- end nav -->
    <div class="mt-2 w-full">
      <div class="lg:flex grid-cols-1 lg:space-y-0 space-y-3 gap-5 justify-between">
        <div>
          <h1 class="text-2xl text-gray-900 dark:text-gray-200 font-medium">
            Analytics
          </h1>
        </div>
        <div class="flex gap-2"></div>
      </div>
    </div>
    <div class="flex gap-3">
      <input class=" drop-down p-2 rounded-md text-sm font-medium text-gray-700" style="background-color: #02ff00; color: black;" v-model="selectedDate" type="date" id="date" name="date" @change="changeDate">
      <div class="flex items-center custom--grid--layout">
        <select v-model="selectedDeviceId" @change="filterByDevice"
          class="drop-down p-2 rounded-md text-sm font-medium text-gray-700"
          style="background-color: #02ff00; color: black;">
          <option value="">All Devices</option>
          <option v-for="device in devices" :key="device.id" :value="device.id">
            {{ device.name }}
          </option>
        </select>
      </div>
    </div>
    <!-- grid wrapper card -->
    <div class=" first-row wrapper-card grid lg:grid-cols-1 grid-cols-1 md:grid-cols-1 gap-2 mt-5">
      <!-- card  -->
      <div class="card bg-white dark:bg-gray-800 w-full rounded-md p-5 border dark:border-gray-700 ">
        <div>
          <div class="w-full  text-lg  text-orange-600 mx-auto">
            <div class="block  w-full">
              <p class=" text-gray-900 dark:text-gray-200 text-center">
                Device
              </p>
            </div>
            <div id="chart" class="w-full">
              <apexchart type="line" height="100%" :options="tempChartOptions" :series="tempSeries"></apexchart>
            </div>
          </div>
        </div>

        <div class="block w-full">
          <p class=" text-gray-900 dark:text-gray-200 text-center">
            Temperature
          </p>
        </div>
      </div>
      <!-- end card -->
    </div>
    <!-- end wrapper card -->
    <!-- start analytics wrapper -->
    <div class="mt-4 w-full">
      <div class="lg:flex grid-cols-1 lg:space-y-0 space-y-3 gap-5 justify-between">
        <div>
          <h1 class="text-2xl text-gray-900 dark:text-gray-200 font-medium">
            Analytics
          </h1>
        </div>
        <div class="flex gap-2"></div>
      </div>
    </div>
    <div class="second-row wrapper-card grid lg:grid-cols-2 grid-cols-1 md:grid-cols-1 gap-2 mt-5">
      <!-- end card -->
      <div
        class="battery-failure-card card bg-white dark:bg-gray-800 w-full rounded-md p-5 border dark:border-gray-700 ">
        <div>
          <div class="w-full  text-lg  text-orange-600 mx-auto">
            <div class="block  w-full">
              <p class=" text-gray-900 dark:text-gray-200">
                Battery Charge Status
              </p>
            </div>
            
              <div id="chart" class="w-full">
                <apexchart type="line" height="250px" :options="batteryChargeChartOptions"
                  :series="batteryChargeStatusSeries">
                </apexchart>
              </div>
          </div>
        </div>
      </div>
      <!-- end card -->
      <!-- card  -->
      <div class="card bg-white dark:bg-gray-800 w-full rounded-md p-5 border dark:border-gray-700 ">
        <div>
          <div class="w-full  text-lg  text-orange-600 mx-auto">
            <div class="block  w-full">
              <p class=" text-gray-900 dark:text-gray-200">
                Power Usage Over Time
              </p>
            </div>
            <div id="chart" class="w-full">
              <apexchart type="line" height="300px" :options="powerOverTimeChartOptions" :series="powerOverTimeSeries">
              </apexchart>
            </div>
          </div>
        </div>
      </div>
      <!-- end card -->
    </div>
    <!-- end analytics wrapper -->
  </div>
</template>
<script>
import VueApexCharts from "vue3-apexcharts";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import store, { getDevicesByStatusQuery, getDevicesByNameQueries, getMetersByIdQuery, getBMSByIdQuery, getDevicesQueries, getMessagesQuery } from "@/store/store";
import Preloader from "@/components/Preloader.vue";
export default {
  components: {
    Preloader,
    apexchart: VueApexCharts,
    DataTable,
    Column
  },
  data() {
    return {
      loading:true,
      //devices: ['Offline Devices', 'Online Devices'],
      selectedDevice: 'Online Devices',
      selectedDate: new Date(),
      metersData: [],
      tempSeries: [{
        name: "Temperature",
        data: []
      }],
      tempChartOptions: {
        chart: {
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
          },
          type: 'line',
          toolbar: {
            show: true,
            autoSelected: 'zoom'
          },
          height: 300,
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
          width: 1
        },
        title: {
          align: 'left',
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
            color: 'white'
          },
        },
        legend: {
          labels: {
            colors: 'white',
          },
        },
        grid: {
          show: false,
          padding: {
            left: 30, // or whatever value that works
            right: 30 // or whatever value that works
          }
        },
        xaxis: {
          tickAmount: 20,
          tickPlacement: 'on',
          // type: "datetime",
          categories: [""],
          labels: {
            style: {
              colors: '#ffffff' // Set y-axis labels to white
            }
          }
        },
        yaxis: {
          labels: {
            style: {
              colors: '#ffffff' // Set y-axis labels to white
            },
            formatter: (value) => { return value.toFixed(0) },
          },
          tickAmount: 5,
          title: {
            text: 'Temperature (°C)',
            style: {
              color: '#ffffff' // Set y-axis labels to white
            }
          },
        },
      },
      batteryPredictionseries: [],
      batteryPredictionchartOptions: {
        chart: {
          type: 'line',
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
          },
          toolbar: {
            show: true,
            autoSelected: 'zoom'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
          width: 1
        },
        title: {
          align: 'left',
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
            color: 'white'
          }
        },
        legend: {
          labels: {
            colors: 'white'
          }
        },
        grid: {
          show: false,
        },
        xaxis: {
          tickAmount: 20,
          tickPlacement: 'on',
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
          labels: {
            style: {
              colors: '#ffffff' // Set x-axis labels to white
            }
          }
        },
        yaxis: {
          title: {
            text: 'SOH / SOC (%)',
            style: {
              color: '#ffffff' // Set y-axis labels to white
            },
          },
          labels: {
            min: 0,
            max: 100,
            formatter: (value) => { return value.toFixed(0) },
            style: {
              colors: '#ffffff' // Set y-axis labels to white
            }
          }
        },
        annotations: {
          yaxis: [
            {
              y: 20,
              borderColor: '#FF4560',
              label: {
                borderColor: '#FF4560',
                style: {
                  color: '#fff',
                  background: '#FF4560'
                },
                text: 'SOH Threshold'
              }
            }
          ]
        }
      },
      powerOverTimeSeries: [],
      powerOverTimeChartOptions: {
        chart: {
          type: 'line',
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
          },
          toolbar: {
            show: true,
            autoSelected: 'zoom'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
          width: 1
        },
        title: {
          align: 'left',
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
            color: 'white'
          },
        },
        legend: {
          labels: {
            colors: 'white',
          },
        },
        grid: {
          show: false
        },
        xaxis: {
          tickAmount: 20,
          tickPlacement: 'on',
          categories: [""],
          labels: {
            style: {
              colors: '#ffffff' // Set y-axis labels to white
            },
            width: "20%",
            align: "left",
          }
        },
        yaxis: {
          title: {
            text: 'Power (KW)',
            style: {
              color: '#ffffff' // Set y-axis labels to white
            }
          },
          labels: {
            style: {
              colors: '#ffffff' // Set y-axis labels to white
            },
            formatter: (value) => { return value.toFixed(0) },
          }
        },
      },
      lineSeries: [],
      intrusionDetectionSeries: [],
      batteryChargeStatusSeries: [],
      batteryChargeChartOptions: {
        chart: {
          type: 'line',
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
          },
          toolbar: {
            show: true,
            autoSelected: 'zoom'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
          width: 1
        },
        title: {
          align: 'left',
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
            color: 'white'
          },
        },
        legend: {
          labels: {
            colors: 'white',
          },
        },
        grid: {
          show: false,
        },
        xaxis: {
          tickAmount: 20,
          tickPlacement: 'on',
          categories: [""],
          labels: {
            style: {
              colors: '#ffffff' // Set y-axis labels to white
            },
            width: "20%",
            align: "left",
          }
        },
        yaxis: {
          title: {
            text: 'State of Charge (%)',
            style: {
              color: '#ffffff' // Set y-axis labels to white
            }
          },
          labels: {
            style: {
              colors: '#ffffff' // Set y-axis labels to white
            },
            formatter: (value) => {
              return value.toFixed(0)
            },
          }
        },
      },
      intrusionDetectionChartOptions: {
        chart: {
          type: 'line',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: true,
            autoSelected: 'zoom'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
          width: 2
        },
        title: {
          align: 'left',
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
            color: 'white'
          }
        },
        legend: {
          labels: {
            colors: 'white'
          }
        },
        grid: {
          show: false
        },
        xaxis: {
          categories: [''],
          labels: {
            style: {
              colors: '#ffffff' // Set x-axis labels to white
            }
          }
        },
        yaxis: {
          title: {
            text: 'Number of Alerts',
            style: {
              color: '#ffffff' // Set y-axis labels to white
            }
          },
          labels: {
            style: {
              colors: '#ffffff' // Set y-axis labels to white
            }
          }
        }
      },
      DevicesData: [],
      userId: null,
      batteryPredictionData: [],
      devices: [],
      selectedDeviceId: ''
    }
  },
  methods: {
    changeDate() {
      this.filterByDevice()
    },
    renderPowerOverTimeChart(messages) {
      let powerIn = []
      let powerOut = []

      let todayMinus24Timestamp = new Date(this.selectedDate).getTime() - 86400000
      let todayMinus24Date = new Date(todayMinus24Timestamp)

      messages = messages.filter(msg => new Date(msg.receivedAt) > todayMinus24Date);

      messages.sort(function (a, b) {
        if (a.receivedAt > b.receivedAt) return 1;
        if (a.receivedAt < b.receivedAt) return -1;
        return 0;
      });

      this.powerOverTimeChartOptions.xaxis.categories.length = 0

      messages.forEach((message) => {
        let parsedMessage = JSON.parse(message.messageText)
        powerOut.push(parsedMessage.pwrOut)
        powerIn.push(parsedMessage.pwrIn)
        this.powerOverTimeChartOptions.xaxis.categories.push(new Date(message.receivedAt).toLocaleTimeString())
      })
      const powerOverTimeSeries = [
        {
          name: "Mains Power (KW)",
          data: powerIn,
          color: "#f44336"
        },
        {
          name: "Battery Power (KW)",
          data: powerOut,
          color: "#00dfff"
        }
      ];
      this.powerOverTimeSeries = powerOverTimeSeries;
    },
    renderBatteryFailurePredictionChart(messages, deviceName) {

      let soh = []
      let soc = []
      let temp = []

      this.batteryPredictionchartOptions.xaxis.categories.length = 0
      this.tempChartOptions.xaxis.categories.length = 0

      let todayMinus24Timestamp = new Date(this.selectedDate).getTime() - 86400000
      let todayMinus24Date = new Date(todayMinus24Timestamp)

      messages = messages.filter(msg => new Date(msg.receivedAt) > todayMinus24Date);

      messages.sort(function (a, b) {
        if (a.receivedAt > b.receivedAt) return 1;
        if (a.receivedAt < b.receivedAt) return -1;
        return 0;
      });

      messages.forEach((message) => {
        let parsedMessage = JSON.parse(message.messageText)
        if (parsedMessage.battery_temperature) {
          // temp.push(tempData)
          temp.push(parsedMessage.battery_temperature)
          this.tempChartOptions.xaxis.categories.push(new Date(message.receivedAt).toLocaleTimeString())
        }
        this.batteryChargeChartOptions.xaxis.categories.push(new Date(message.receivedAt).toLocaleTimeString())
        this.batteryPredictionchartOptions.xaxis.categories.push(new Date(message.receivedAt).toLocaleTimeString())


        if (parsedMessage.battery_soc) {
          if (parsedMessage.battery_soc.toString().includes("%")) {
            soc.push(parsedMessage.battery_soc.replace("%", ""))
          } else {
            soc.push(parsedMessage.battery_soc)
          }
        }

        if (parsedMessage.battery_soh) {
          if (parsedMessage.battery_soh.toString().includes("%")) {
            soh.push(parsedMessage.battery_soh.replace("%", ""))
          } else {
            soh.push(parsedMessage.battery_soh)
          }
        }
      })
      this.batteryPredictionseries = [
        {
          name: "Battery SOH (%)",
          data: soh
        },
        {
          name: "Battery SOC (%)",
          data: soc
        }
      ];

      this.tempSeries = [
        {
          name: deviceName,
          data: temp,
          color: "#3eff00"
        }
      ];

      const batterySeries = [
        {
          name: "Power Charge (%)",
          data: soc,
          color: "#00dfff"
        }
      ];
      this.batteryChargeStatusSeries = batterySeries;
    },
    renderIntrusionDetectionChart(messages) {
      let categories = this.calculate24Hours();
      // let messages = deviceData.messages.edges;
      // Initialize arrays to store the count of alerts for each severity level
      let criticalAlerts = new Array(24).fill(0);
      let mediumAlerts = new Array(24).fill(0);
      let lowAlerts = new Array(24).fill(0);
      let infoAlerts = new Array(24).fill(0);
      let otherAlerts = new Array(24).fill(0);

      this.intrusionDetectionChartOptions.xaxis.categories.length = 0;
      categories.reverse().forEach(cat => {
        this.intrusionDetectionChartOptions.xaxis.categories.push(cat.displayDate);
      });

      messages.forEach(message => {
        for (let i = 0; i < 24; i++) {
          if (message.receivedAt.includes(categories[i].date)) {
            switch (message.severity) {
              case 'Critical':
                criticalAlerts[i]++;
                break;
              case 'Medium':
                mediumAlerts[i]++;
                break;
              case 'Low':
                lowAlerts[i]++;
                break;
              case 'Info':
                infoAlerts[i]++;
                break;
              default:
                otherAlerts[i]++;
                break;
            }
          }
        }
      });

      this.intrusionDetectionSeries = [
        { name: "Critical", data: criticalAlerts, color: "#FF0000" },
        { name: "Medium", data: mediumAlerts, color: "#FFA500" },
        { name: "Low", data: lowAlerts, color: "#FFFF00" },
        { name: "Info", data: infoAlerts, color: "#008FFB" },
      ];
    },
    getDevicesById() {
      this.$apollo.query({
        query: getDevicesQueries.getDevicesQuery,
        variables: {
          userId: this.userId
        }
      }).then(async (data) => {
        this.devices = data.data.devices.devices;
        this.selectedDeviceId = this.devices[0].id
        this.filterByDevice()
      }).catch((error) => {
        // Error
        console.error('Error in login getUsers query => ', error)
      })
    },
    getLast24HoursMessages(deviceId, type, date, deviceName) {
      this.$apollo.query({
        query: getMessagesQuery.getLast24HoursMessages,
        variables: {
          deviceId: deviceId,
          messageType: type,
          date: date
        }
      }).then(async (data) => {
        switch (type) {
          case "BMS":
            this.renderBatteryFailurePredictionChart(data.data.messages.messages, deviceName)
            break;
          case "Meter":
            this.renderPowerOverTimeChart(data.data.messages.messages)
            break;
          case "IDS":
            this.renderIntrusionDetectionChart(data.data.messages.messages);
            break;
        }
      }).catch((error) => {
        // Error
        console.error('Error in Operator Analytics get messages => ', error)
      })
    },
    calculate24Hours() {
      let today = new Date()
      let last24 = []
      for (let i = 0; i < 24; i++) {
        let date = new Date(today.getTime() - 3600000 * i)

        let localString = date.toISOString().substring(0, 14)
        let hour = date.getHours()

        let dateForChart = {
          displayDate: hour + ":00",
          date: localString
        }

        last24.push(dateForChart)
      }
      return last24
    },
    filterByDevice() {
      this.loading = true;
      const selectedDevice = this.devices.find(device => device.id === this.selectedDeviceId);
      let now = new Date()
      let selectedDateObject = new Date(this.selectedDate)
      selectedDateObject.setHours(now.getHours())
      selectedDateObject.setMinutes(now.getMinutes())
      selectedDateObject.setSeconds(now.getSeconds())
      let todayMinus24Timestamp = selectedDateObject.getTime() - 86400000
      // let todayMinus24Timestamp = new Date().getTime() - 86400000
      let todayMinus24Date = new Date(todayMinus24Timestamp)
      let formattedDateString = todayMinus24Date.toISOString().split('.')[0];
      if (selectedDevice) {
        this.getLast24HoursMessages(this.selectedDeviceId, "BMS", formattedDateString, selectedDevice.name)
        this.getLast24HoursMessages(this.selectedDeviceId, "Meter", formattedDateString, selectedDevice.name)
        this.getLast24HoursMessages(this.selectedDeviceId, "IDS", formattedDateString, selectedDevice.name)
      }
      this.loading = false;
    },
  },
  mounted() {
    this.loading = true;
    if (store.getters.getUser) {
      let user = store.getters.getUser;
      let userId = user.id;
      this.userId = userId
    }
    this.getDevicesById()
    this.$nextTick(() => {
      window.dispatchEvent(new Event('resize'));
    });
    this.loading = false;
  }
}
</script>
<style scoped>
.drop-down:focus {
  outline: none !important;
  box-shadow: none !important;
}

.battery-item {
  width: 90%;
  height: 200px;
  background: linear-gradient(to right, #222222 0%, #2e2d2d 50%, #444343 100%);
  border-radius: 20px;
  position: relative;
}

.battery-item::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  width: 50%;
  height: 80%;
  background-color: #1D51FE;
  filter: blur(10px);
  transform: scaleX(1.5), translateY(-50%);
  transform-origin: left;
  opacity: 0.6;
}

.battery-item::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-50%);
  height: 50%;
  width: 15px;
  background-color: #393D41;
  z-index: 9999;
  border-radius: 20px;
}

.battery-percentage {
  color: white;
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translateY(-50%);
}

.battery-text {
  color: white;
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.2em;
  line-height: 1.8;
}

::v-deep .p-datatable .p-datatable-thead>tr .p-column-title {
  font-size: .8em !important;
  width: 90px;
}

::v-deep .table .p-datatable-wrapper {
  width: 460px !important;
}

::v-deep .security-alert-table .p-datatable-wrapper {
  width: 950px !important;
}
.custom--grid--layout{
  padding: 0 !important;
}
.custom--grid--layout > * {
  margin: 0 !important;
  height:100%;
}
@media screen and (max-width: 1349px) and (min-width: 300px) {
  ::v-deep .second-row {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  ::v-deep .analytics-table .p-datatable-wrapper {
    width: 620px !important;
  }

  ::v-deep .security-alert-table .p-datatable-wrapper {
    max-width: initial !important;
    min-width: initial !important;
    width: 680px !important;
  }

  .h-auto {
    height: 300px;
    width: 80%;
  }

  .battery-item {
    width: 80%;
  }
}

@media screen and (max-width: 950px) and (min-width: 300px) {
  ::v-deep .second-row {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  ::v-deep .analytics-table .p-datatable-wrapper {
    width: 720px !important;
  }

  ::v-deep .security-alert-table .p-datatable-wrapper {
    max-width: initial !important;
    min-width: initial !important;
    width: 720px !important;
  }
  .battery-item {
    width: 50%;
    margin-inline: auto;
  }
}

@media screen and (max-width: 850px) and (min-width: 300px) {
  ::v-deep .second-row {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  ::v-deep .analytics-table .p-datatable-wrapper {
    width: 520px !important;
  }

  ::v-deep .security-alert-table .p-datatable-wrapper {
    max-width: initial !important;
    min-width: initial !important;
    width: 550px !important;
  }
}

@media screen and (max-width: 750px) and (min-width: 300px) {
  ::v-deep .second-row {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .h-auto {
    height: 220px;
  }

  .battery-failure-card,
  .security-devices-card {
    width: 100%;
    overflow-x: hidden;
  }

  ::v-deep .analytics-table .p-datatable-wrapper {
    width: 470px !important;
  }

  ::v-deep .security-alert-table .p-datatable-wrapper {
    max-width: initial !important;
    min-width: initial !important;
    width: 470px !important;
  }
}

@media screen and (max-width: 550px) and (min-width: 300px) {

  .h-auto {
    height: 300px;
  }

  .battery-failure-card,
  .security-devices-card {
    width: 100%;
    overflow-x: hidden;
  }

  ::v-deep .analytics-table .p-datatable-wrapper {
    width: 370px !important;
  }

  ::v-deep .security-alert-table .p-datatable-wrapper {
    max-width: initial !important;
    min-width: initial !important;
    width: 370px !important;
  }
  .battery-item {
    width: 80%;
    margin-inline: auto;
  }
}
</style>