<template>
  <div>
    <AlertsOperator v-if="userType == 'operator'" /> 
    <AlertsUser v-if="userType != 'operator'"/>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import AlertsOperator from "../components/AlertsOperator.vue"
import AlertsUser from "../components/AlertsUser.vue"
import store from "@/store/store";
export default {
  components: {
    apexchart: VueApexCharts,
    AlertsOperator,
    AlertsUser
  },
  data() {
    return {
      chartOptions: {
        colors: ["#1aa1e1", "#ff9d14", "#725E54", "#cb1515", "#F0F0C9"],
        chart: {
          id: "realtime",
          background: "#000",
          animations: {
            enabled: true,
            easing: "linear",
            dynamicAnimation: {
              speed: 7600,
            },
          },
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          range: 26,
          style: {
            colors: ["white"],
            fontSize: "10px",
          },
        },
        yaxis: {
          max: 900,
        },
        legend: {
          show: true,
        },
      },
      series: [
        {
          name: "Info",
          data: [],
        },
        {
          name: "Warning",
          data: [],
        },
        {
          name: "Error",
          data: [],
        },
        {
          name: "Critical",
          data: [],
        },
        {
          name: "Time",
          data: [],
        },
      ],
    };
  },
  created() {
    this.updateData();
    this.timer = setInterval(this.updateData, 10000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    updateData() {
      for (let series of this.series) {
        series.data = [];
        for (let i = 0; i < 26; i++) {
          series.data.push(Math.floor(Math.random() * 900));
        }
      }
    },
  },
  computed: {
    userType() {
      return store.getters.getUser.type;
    },
  },
};
</script>

<style></style>
