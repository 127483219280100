<template>
    <div class="flex items-center justify-between mt-6 w-full">
        <h1 class="text-2xl text-gray-900 dark:text-gray-200 font-medium py-3 px-4 min-w-[150px]">
            Components
        </h1>
    </div>
    <div class="flex items-end justify-end p-4 pb-0 w-full ">
        <button @click="openModal" class="bg-[#318bc8] hover:bg-[#318bc8] text-white font-bold py-2 px-4 rounded">
            + Import
        </button>
        <transition name="modal">
            <div v-if="isModalOpen" class="fixed inset-0 flex items-center justify-center z-50">
                <!-- Overlay with @click to close modal -->
                <div @click="closeModal" class="fixed inset-0 z-40 bg-black bg-opacity-50"></div>

                <div class="z-50 bg-white rounded p-8 max-w-md w-full text-center modal">
                    <h2 class="text-2xl font-semibold mb-4">Import Components</h2>
                    <div class="mb-4 flex items-center justify-center cursor-pointer  custom--grid--layout">
                        <label for="invertersName"> Name</label>
                        <input type="text" v-model="inverter.invertersName" name="invertersName" id="invertersName"
                            class="ml-3 w-full text-md text-black border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary peer"
                            placeholder="Input Component Name" required />
                    </div>
                    <div class="mb-4 flex items-center justify-center cursor-pointer  custom--grid--layout">
                        <label for="invertersManufacturer"> Manufacturer </label>
                        <input type="text" v-model="inverter.invertersManufacturer" name="invertersManufacturer" id="invertersManufacturer"
                            class="ml-3 w-full text-md text-black border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary peer"
                            placeholder="Input Component Name" required />
                    </div>
                    <div class="mb-4 flex items-center justify-center cursor-pointer  custom--grid--layout">
                        <label for="invertersVersion"> Version </label>
                        <input type="text" v-model="inverter.invertersVersion" name="invertersVersion" id="invertersVersion"
                            class="ml-3 w-full text-md text-black border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary peer"
                            placeholder="Input Component Name" required />
                    </div>
                    <div class="mb-4 flex items-center justify-center cursor-pointer  custom--grid--layout">
                        <select id="componentType" v-model="selectedComponentType"
                            class="mt-1 p-2 border rounded-md  cursor-pointer">
                            <option value="" disabled selected>Select Component Type</option>
                            <option v-for="(type, index) in componentTypes" :key="index" :value="type">{{ type }}
                            </option>
                        </select>
                    </div>
                    <div class="file-input btn-1">
                        <input type="file" name="file-input" id="file-input" class="file-input__input "
                            @change="captureFiles" ref="fileInput" accept=".json" multiple />
                        <label class="file-input__label" for="file-input" @click="importData">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="upload"
                                class="svg-inline--fa fa-upload fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512">
                                <font-awesome-icon :icon="['fas', 'upload']" style="color: black" />
                            </svg>
                            <span>Import component file</span>
                        </label>
                    </div>
                    <!-- Loading spinner -->
                    <div v-if="loading" class="loadercontainer">
                        <span class="loader"></span>
                    </div>
                    <div class="flex justify-middle mt-4 modal--btns">
                        <button @click="importData"
                            class="bg-[#318bc8] hover:bg-[#318bc8] text-white font-bold py-2 px-4 rounded mr-3">
                            Import
                        </button>
                        <button @click="closeModal"
                            class="bg-[#CB1515] hover:bg-[#CB1515] text-white font-bold py-2 px-4 rounded">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </transition>
    </div>
    <div class="card bg-white dark:bg-gray-800 rounded-md p-5 border dark:border-gray-700 flex mt-6 mb-6 mx-6 px-4">
        <div class="flex flex-col w-full">
            <DataTable :value="inverters" :rowStyle="rowStyle" :rowClass="rowClasses" tableStyle="min-width: 50rem">
                <Column field="model" header="Inverter Model">
                    <template #body="slotProps">
                        <span class="text-blue-400 underline cursor-pointer" @click="navigate(slotProps.data)">
                            {{ slotProps.data.model }}
                        </span>
                    </template>
                </Column>
                <Column field="version" header="Version " class="column-health">
                    <template #body="slotProps">
                        {{ slotProps.data.version }}
                    </template>
                </Column>
                <Column field="manufacturer" header="Manufacturer" class="column-status">
                    <template #body="slotProps">
                        {{ slotProps.data.manufacturer }}
                    </template>
                </Column>
                <Column field="version" header="Version">
                    <template #body="slotProps">
                        {{ slotProps.data.version }}
                    </template>
                </Column>
                <Column field="serialNumber" header="Serial Number">
                    <template #body="slotProps">
                        {{ slotProps.data.serialNumber }}
                    </template>
                </Column>
                <Column field="addedDate" header="Date Added">
                    <template #body="slotProps">
                        {{ slotProps.data.inserted_at }}
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import gql from 'graphql-tag'
export default {
    components: {
        DataTable,
        Column,
    },
    data() {
        return {
            loading: false,
            finishProccessingInverterData: {
                common: false,
                inverterSingle: false,
                inverterThree: false,
                nameplate: false,
                settings: false,
                status: false,
                controls: false,
                voltVar: false,
                freqWattParam: false,
                reactiveCurrent: false,
                mppt: false,
                voltWatt: false,
            },
            inverters: [],
            selectedFiles: [],
            uploadedFiles: [],
            isModalOpen: false,
            selectedComponentType: '', // Variable to store the selected component type
            componentTypes: ['Inverter', 'Meter', 'BMS'], // Array of component types
            components: [

            ],
            invertersArray: [],
            inverter: {
                invertersId: "e7d7af79-0126-4ec8-ad19-8ef864634236",
                invertersName: "",
                invertersModel: null,
                invertersVersion: "",
                invertersManufacturer: "",
                voltWattId: null,
                voltWattVoltWattId: 1,
                voltWattL: null,
                voltWattActcrv: null,
                voltWattModena: null,
                voltWattWintms: null,
                voltWattRvrttms: null,
                voltWattRmptms: null,
                voltWattNcrv: null,
                voltWattNpt: null,
                voltWattVSf: null,
                voltWattDeptrefSf: null,
                voltWattRmpincdecSf: null,
                voltVarCurveId: null,
                voltVarCurveVoltVarId: null,
                voltVarCurveActpt: null,
                voltVarCurveDeptref: null,
                voltVarCurveV1: null,
                voltVarCurveVar1: null,
                voltVarCurveV2: null,
                voltVarCurveVar2: null,
                voltVarCurveV3: null,
                voltVarCurveVar3: null,
                voltVarCurveV4: null,
                voltVarCurveVar4: null,
                voltVarCurveV5: null,
                voltVarCurveVar5: null,
                voltVarCurveV6: null,
                voltVarCurveVar6: null,
                voltVarCurveV7: null,
                voltVarCurveVar7: null,
                voltVarCurveV8: null,
                voltVarCurveVar8: null,
                voltVarCurveV9: null,
                voltVarCurveVar9: null,
                voltVarCurveV10: null,
                voltVarCurveVar10: null,
                voltVarCurveV11: null,
                voltVarCurveVar11: null,
                voltVarCurveV12: null,
                voltVarCurveVar12: null,
                voltVarCurveV13: null,
                voltVarCurveVar13: null,
                voltVarCurveV14: null,
                voltVarCurveVar14: null,
                voltVarCurveV15: null,
                voltVarCurveVar15: null,
                voltVarCurveV16: null,
                voltVarCurveVar16: null,
                voltVarCurveV17: null,
                voltVarCurveVar17: null,
                voltVarCurveV18: null,
                voltVarCurveVar18: null,
                voltVarCurveV19: null,
                voltVarCurveVar19: null,
                voltVarCurveV20: null,
                voltVarCurveVar20: null,
                voltVarCurveCrvnam: null,
                voltVarCurveRmptms: null,
                voltVarCurveRmpdectmm: null,
                voltVarCurveRmpinctmm: null,
                voltVarCurveReadonly: null,
                voltWattCurveId: null,
                voltWattCurveVoltWattId: null,
                voltWattCurveActpt: null,
                voltWattCurveDeptref: null,
                voltWattCurveV1: null,
                voltWattCurveW1: null,
                voltWattCurveV2: null,
                voltWattCurveW2: null,
                voltWattCurveV3: null,
                voltWattCurveW3: null,
                voltWattCurveV4: null,
                voltWattCurveW4: null,
                voltWattCurveV5: null,
                voltWattCurveW5: null,
                voltWattCurveV6: null,
                voltWattCurveW6: null,
                voltWattCurveV7: null,
                voltWattCurveW7: null,
                voltWattCurveV8: null,
                voltWattCurveW8: null,
                voltWattCurveV9: null,
                voltWattCurveW9: null,
                voltWattCurveV10: null,
                voltWattCurveW10: null,
                voltWattCurveV11: null,
                voltWattCurveW11: null,
                voltWattCurveV12: null,
                voltWattCurveW12: null,
                voltWattCurveV13: null,
                voltWattCurveW13: null,
                voltWattCurveV14: null,
                voltWattCurveW14: null,
                voltWattCurveV15: null,
                voltWattCurveW15: null,
                voltWattCurveV16: null,
                voltWattCurveW16: null,
                voltWattCurveV17: null,
                voltWattCurveW17: null,
                voltWattCurveV18: null,
                voltWattCurveW18: null,
                voltWattCurveV19: null,
                voltWattCurveW19: null,
                voltWattCurveV20: null,
                voltWattCurveW20: null,
                voltWattCurveCrvnam: null,
                voltWattCurveRmppt1tms: null,
                voltWattCurveRmpdectmm: null,
                voltWattCurveRmpinctmm: null,
                voltWattCurveReadonly: null,
                freqWattParamId: null,
                freqWattParamFreqWattParamId: null,
                freqWattParamL: null,
                freqWattParamWgra: null,
                freqWattParamHzstr: null,
                freqWattParamHzstop: null,
                freqWattParamHysena: null,
                freqWattParamModena: null,
                freqWattParamHzstopwgra: null,
                freqWattParamWgraSf: null,
                freqWattParamHzstrstopSf: null,
                freqWattParamRmpincdecSf: null,
                freqWattParamPad: null,
                inverterId: null,
                inverterType: "single",
                inverterInverterId: null,
                inverterL: null,
                inverterA: null,
                inverterApha: null,
                inverterAphb: null,
                inverterAphc: null,
                inverterASf: null,
                inverterPpvphab: null,
                inverterPpvphbc: null,
                inverterPpvphca: null,
                inverterPhvpha: null,
                inverterPhvphb: null,
                inverterPhvphc: null,
                inverterVSf: null,
                inverterW: null,
                inverterWSf: null,
                inverterHz: null,
                inverterHzSf: null,
                inverterVa: null,
                inverterVaSf: null,
                inverterVar: null,
                inverterVarSf: null,
                inverterPf: null,
                inverterPfSf: null,
                inverterWh: null,
                inverterWhSf: null,
                inverterDca: null,
                inverterDcaSf: null,
                inverterDcv: null,
                inverterDcvSf: null,
                inverterDcw: null,
                inverterDcwSf: null,
                inverterTmpcab: null,
                inverterTmpsnk: null,
                inverterTmptrns: null,
                inverterTmpot: null,
                inverterTmpSf: null,
                inverterSt: null,
                inverterStvnd: null,
                inverterEvt1: null,
                inverterEvt2: null,
                inverterEvtvnd1: null,
                inverterEvtvnd2: null,
                inverterEvtvnd3: null,
                inverterEvtvnd4: null,
                voltVarId: null,
                voltVarVoltVarId: null,
                voltVarL: null,
                voltVarActcrv: null,
                voltVarModena: null,
                voltVarWintms: null,
                voltVarRvrttms: null,
                voltVarRmptms: null,
                voltVarNcrv: null,
                voltVarNpt: null,
                voltVarVSf: null,
                voltVarDeptrefSf: null,
                voltVarRmpincdecSf: null,
                statusId: null,
                statusStatusId: null,
                statusL: null,
                statusPvconn: null,
                statusStorconn: null,
                statusEcpconn: null,
                statusActwh: null,
                statusActvah: null,
                statusActvarhq1: null,
                statusActvarhq2: null,
                statusActvarhq3: null,
                statusActvarhq4: null,
                statusVaraval: null,
                statusVaravalSf: null,
                statusWaval: null,
                statusWavalSf: null,
                statusStsetlimmsk: null,
                statusStactctl: null,
                statusTmsrc: null,
                statusTms: null,
                statusRtst: null,
                statusRis: null,
                statusRisSf: null,
                mpptId: null,
                mpptMpptId: null,
                mpptL: null,
                mpptDcaSf: null,
                mpptDcvSf: null,
                mpptDcwSf: null,
                mpptDcwhSf: null,
                mpptEvt: null,
                mpptN: null,
                mpptTmsper: null,
                mpptModuleId: null,
                mpptModuleMpptId: null,
                mpptModuleIdstr: null,
                mpptModuleDca: null,
                mpptModuleDcv: null,
                mpptModuleDcw: null,
                mpptModuleDcwh: null,
                mpptModuleTms: null,
                mpptModuleTmp: null,
                mpptModuleDcst: null,
                mpptModuleDcevt: null,
                reactiveCurrentId: null,
                reactiveCurrentReactiveCurrentId: null,
                reactiveCurrentL: null,
                reactiveCurrentArgramod: null,
                reactiveCurrentArgrasag: null,
                reactiveCurrentArgraswell: null,
                reactiveCurrentModena: null,
                reactiveCurrentFiltms: null,
                reactiveCurrentDbvmin: null,
                reactiveCurrentDbvmax: null,
                reactiveCurrentBlkznv: null,
                reactiveCurrentHysblkznv: null,
                reactiveCurrentBlkzntmms: null,
                reactiveCurrentHoldtmms: null,
                reactiveCurrentArgraSf: null,
                reactiveCurrentVrefpctSf: null,
                reactiveCurrentPad: null,
                nameplateId: null,
                nameplateNameplateId: null,
                nameplateL: null,
                nameplateDertyp: null,
                nameplateWrtg: null,
                nameplateWrtgSf: null,
                nameplateVartg: null,
                nameplateVartgSf: null,
                nameplateVarrtgq1: null,
                nameplateVarrtgq2: null,
                nameplateVarrtgq3: null,
                nameplateVarrtgq4: null,
                nameplateVarrtgSf: null,
                nameplateArtg: null,
                nameplateArtgSf: null,
                nameplatePfrtgq1: null,
                nameplatePfrtgq2: null,
                nameplatePfrtgq3: null,
                nameplatePfrtgq4: null,
                nameplatePfrtgSf: null,
                nameplateWhrtg: null,
                nameplateWhrtgSf: null,
                nameplateAhrrtg: null,
                nameplateAhrrtgSf: null,
                nameplateMaxcharte: null,
                nameplateMaxcharteSf: null,
                nameplateMaxdischarte: null,
                nameplateMaxdischarteSf: null,
                nameplatePad: null,
                controlsId: null,
                controlsControlsId: null,
                controlsL: null,
                controlsConnWintms: null,
                controlsConnRvrttms: null,
                controlsConn: null,
                controlsWmaxlimpct: null,
                controlsWmaxlimpctWintms: null,
                controlsWmaxlimpctRvrttms: null,
                controlsWmaxlimpctRmptms: null,
                controlsWmaxlimEna: null,
                controlsOutpfset: null,
                controlsOutpfsetWintms: null,
                controlsOutpfsetRvrttms: null,
                controlsOutpfsetRmptms: null,
                controlsOutpfsetEna: null,
                controlsVarwmaxpct: null,
                controlsVarmaxpct: null,
                controlsVaravalpct: null,
                controlsVarpctWintms: null,
                controlsVarpctRvrttms: null,
                controlsVarpctRmptms: null,
                controlsVarpctMod: null,
                controlsVarpctEna: null,
                controlsWmaxlimpctSf: null,
                controlsOutpfsetSf: null,
                controlsVarpctSf: null,
                settingsId: null,
                settingsSettingsId: null,
                settingsL: null,
                settingsWmax: null,
                settingsVref: null,
                settingsVrefofs: null,
                settingsVmax: null,
                settingsVmin: null,
                settingsVamax: null,
                settingsVarmaxq1: null,
                settingsVarmaxq2: null,
                settingsVarmaxq3: null,
                settingsVarmaxq4: null,
                settingsWgra: null,
                settingsPfminq1: null,
                settingsPfminq2: null,
                settingsPfminq3: null,
                settingsPfminq4: null,
                settingsVaract: null,
                settingsClctotva: null,
                settingsMaxrmprte: null,
                settingsEcpnomhz: null,
                settingsConnph: null,
                settingsWmaxSf: null,
                settingsVrefSf: null,
                settingsVrefofsSf: null,
                settingsVminmaxSf: null,
                settingsVamaxSf: null,
                settingsVarmaxSf: null,
                settingsWgraSf: null,
                settingsPfminSf: null,
                settingsMaxrmprteSf: null,
                settingsEcpnomhzSf: null,
                commonId: null,
                commonCommonId: null,
                commonL: null,
                commonMn: null,
                commonMd: null,
                commonOpt: null,
                commonVr: null,
                commonSn: null,
                commonDa: null,
                commonPad: null,
                deviceId: "7241bd1b-4470-41f1-9f6c-2d93690d5939"
            },
        };
    },
    watch: {
        allDataProcessed(newValue) {
            console.log("DEBUG ANDER => ", newValue)
            if (newValue) {
                this.addInvertedToDb();
            }
        }
    },
    computed: {
        // Check if all properties are true
        allDataProcessed() {
            const result = Object.values(this.finishProccessingInverterData).every(value => value);
            return result;
        }
    },
    methods: {
        captureFiles(event) {
            this.selectedFiles = event.target.files;
        },
        openModal() {
            document.body.classList.add("overflow-hidden");
            this.isModalOpen = true;
        },
        closeModal() {
            document.body.classList.remove("overflow-hidden");
            this.isModalOpen = false;
        },
        async importData() {
            console.log("DEBUG ANDER: IMPORT DATA => ",)
            if (this.selectedFiles.length === 0) {
                // No files selected
                return;
            }

            // Check if selectedComponentType is "Inverter"
            if (this.selectedComponentType.toLowerCase() === "inverter") {
                try {
                    // Loop through each selected file
                    for (let i = 0; i < this.selectedFiles.length; i++) {
                        const file = this.selectedFiles[i];

                        // Show loading spinner
                        this.loading = true;

                        // Check if file size exceeds 2MB (2 * 1024 * 1024 bytes)
                        if (file.size > 2 * 1024 * 1024) {
                            console.error(`File ${file.name} exceeds the size limit of 2MB.`);
                            continue; // Skip this file and proceed to the next one
                        }

                        try {
                            // Read file content
                            const fileContent = await this.readFileContent(file);

                            // Parse JSON content
                            const jsonData = JSON.parse(fileContent);

                            // Push jsonData to invertersArray
                            this.invertersArray.push(jsonData);

                            // Call buildInverterModel method
                            const componentModel = await this.buildInverterModel(jsonData);
                            console.log("DEBUG ANDER: COMPONENT MODEL => ", componentModel)
                        } catch (err) {
                            console.error(`Error reading file ${file.name}:`, err);
                        } finally {
                            // Hide loading spinner after processing the file
                            this.loading = false;
                        }
                    }
                    console.log("DEBUG ANDER: ADD TO DB CALLED => ", this.inverter)
                    let response = await this.addInvertedToDb()
                    console.log("DEBUG ANDER: API CALL RESPONSE => ", response)

                } catch (error) {
                    console.error('Error uploading files:', error);
                }
            } else if (this.selectedComponentType.toLowerCase() === "meter") {
                try {
                    // Loop through each selected file
                    for (let i = 0; i < this.selectedFiles.length; i++) {
                        const file = this.selectedFiles[i];

                        // Show loading spinner
                        this.loading = true;

                        // Check if file size exceeds 2MB (2 * 1024 * 1024 bytes)
                        if (file.size > 2 * 1024 * 1024) {
                            console.error(`File ${file.name} exceeds the size limit of 2MB.`);
                            continue; // Skip this file and proceed to the next one
                        }

                        try {
                            // Read file content
                            const fileContent = await this.readFileContent(file);

                            // Parse JSON content
                            const jsonData = JSON.parse(fileContent);

                            // Add logic for handling meter component
                            // For example:
                            this.metersArray.push(jsonData);
                            const componentModel = await this.buildMeterModel(jsonData);
                        } catch (err) {
                            console.error(`Error reading file ${file.name}:`, err);
                        } finally {
                            // Hide loading spinner after processing the file
                            this.loading = false;
                        }
                    }
                } catch (error) {
                    console.error('Error uploading files:', error);
                }
            } else if (this.selectedComponentType.toLowerCase() === "bms") {
                try {
                    // Loop through each selected file
                    for (let i = 0; i < this.selectedFiles.length; i++) {
                        const file = this.selectedFiles[i];

                        // Show loading spinner
                        this.loading = true;

                        // Check if file size exceeds 2MB (2 * 1024 * 1024 bytes)
                        if (file.size > 2 * 1024 * 1024) {
                            console.error(`File ${file.name} exceeds the size limit of 2MB.`);
                            continue; // Skip this file and proceed to the next one
                        }

                        try {
                            // Read file content
                            const fileContent = await this.readFileContent(file);

                            // Parse JSON content
                            const jsonData = JSON.parse(fileContent);

                            // Add logic for handling bms component
                            // For example:
                            this.bmsArray.push(jsonData);
                            const componentModel = await this.buildBMSModel(jsonData);
                        } catch (err) {
                            console.error(`Error reading file ${file.name}:`, err);
                        } finally {
                            // Hide loading spinner after processing the file
                            this.loading = false;
                        }
                    }
                } catch (error) {
                    console.error('Error uploading files:', error);
                }
            } else {
                console.log('The selected component type is not "Inverter", "Meter", or "BMS".');
                // You can add handling for other component types here if needed
            }
        },
        async uploadFile(event) {
            const files = event.target.files;
            if (!files || files.length === 0) {
                // No files selected or files array is empty
                return;
            }
            // Show loading spinner
            this.loading = true;

            try {
                // Loop through each uploaded file
                for (let i = 0; i < files.length; i++) {
                    const file = files[i];

                    // Check if file size exceeds 2MB (2 * 1024 * 1024 bytes)
                    if (file.size > 2 * 1024 * 1024) {
                        console.error(`File ${file.name} exceeds the size limit of 2MB.`);
                        continue; // Skip this file and proceed to the next one
                    }

                    try {
                        // Read file content
                        const fileContent = await this.readFileContent(file);

                        // Parse JSON content
                        const jsonData = JSON.parse(fileContent);

                        // Extract group name from JSON
                        const groupName = jsonData.group.name;
                        // Determine model based on group name
                        let model;
                        switch (groupName) {
                            case 'inverter':
                                model = this.buildInverterModel(jsonData); // Implement buildInverterModel method
                                break;
                            case 'meter':
                                model = this.buildMeterModel(jsonData); // Implement buildMeterModel method
                                break;
                            case 'bms':
                                model = this.buildBMSModel(jsonData); // Implement buildBMSModel method
                                break;
                            default:
                                console.log(`Unknown group name: ${groupName}`);
                                continue; // Skip to the next file
                        }
                    } catch (err) {
                        console.error(`Error reading file ${file.name}:`, err);
                    }
                }
            } catch (error) {
                console.error('Error uploading files:', error);
            } finally {
                // Hide loading spinner after upload completes
                this.loading = false;
            }
        },
        readFileContent(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
                reader.readAsText(file);
            });
        },
        async buildInverterModel(jsonData) {
            switch (jsonData.group.name.toLowerCase()) {
                case 'common':
                    // Add logic for common
                    await this.handleCommonGroup(jsonData.group.points);
                    break;
                case 'invertersingle':
                    // Add logic for invertersingle
                    await this.handleInvertersingleGroup(jsonData.group.points, jsonData.group.id);
                    break;
                case 'inverterthree':
                    // Add logic for inverterthree
                    await this.handleInverterthreeGroup(jsonData.group.points);
                    break;
                case 'nameplate':
                    // Add logic for nameplate
                    await this.handleNameplateGroup(jsonData.group.points);
                    break;
                case 'settings':
                    // Add logic for settings
                    await this.handleSettingsGroup(jsonData.group.points);
                    break;
                case 'status':
                    // Add logic for status
                    await this.handleStatusGroup(jsonData.group.points);
                    break;
                case 'controls':
                    // Add logic for controls
                    await this.handleControlsGroup(jsonData.group.points);
                    break;
                case 'volt_var':
                    // Add logic for volt_var
                    await this.handleVoltVarCurveGroup(jsonData.group.points, jsonData.group);
                    break;
                case 'freq_watt_param':
                    // Add logic for freq_watt_param
                    await this.handleFreqWattParamGroup(jsonData.group.points);
                    break;
                case 'reactive_current':
                    // Add logic for reactive_current
                    await this.handleReactiveCurrentGroup(jsonData.group.points);
                    break;
                case 'volt_watt':
                    await this.handleVoltWattGroup(jsonData.group.points, jsonData.group);
                    break;
                case 'mppt':
                    // Add logic for mppt
                    await this.handleMpptGroup(jsonData.group.points, jsonData.group);
                    break;
                default:
                    console.log('Unknown group name:', jsonData.group.name);
                    break;

            }
            console.log("DEBUG ANDER: INVERTER DATA => ", this.inverter)
        },
        handleCommonGroup(points) {
            this.loading = true;
            points.forEach(point => {
                switch (point.name) {
                    case 'ID':
                        // commonCommonId: Int
                        this.inverter.commonCommonId = point.value;
                        break;
                    case 'L':
                        // commonL: Int
                        this.inverter.commonL = point.value;
                        break;
                    case 'Mn':
                        // commonMn: String
                        this.inverter.commonMn = point.name;
                        break;
                    case 'Md':
                        // commonMd: String
                        this.inverter.commonMd = point.name;
                        break;
                    case 'Opt':
                        // commonOpt: String
                        this.inverter.commonOpt = point.name;
                        break;
                    case 'Vr':
                        // commonVr: String
                        this.inverter.commonVr = point.name;
                        break;
                    case 'SN':
                        // commonSn: String
                        this.inverter.commonSn = point.name;
                        break;
                    case 'DA':
                        // commonDa: Int
                        this.inverter.commonDa = point.value;
                        break;
                    case 'Pad':
                        // commonPad: Int
                        this.inverter.commonPad = point.value;
                        break;
                    default:
                        console.log('Unexpected point.name:', point.name);
                        break;
                }
            });
            this.finishProccessingInverterData.common = true;
        },
        handleInvertersingleGroup(points, id) {
            // Show loading spinner
            this.loading = true;
            points.forEach(point => {
                switch (point.name) {
                    case 'ID':
                        this.inverter.inverterInverterId = point.value;
                        break;
                    case 'L':
                        this.inverter.inverterL = point.value;
                        break;
                    case 'A':
                        this.inverter.inverterA = point.value;
                        break;
                    case 'AphA':
                        this.inverter.inverterApha = point.value;
                        break;
                    case 'AphB':
                        this.inverter.inverterAphb = point.value;
                        break;
                    case 'AphC':
                        this.inverter.inverterAphc = point.value;
                        break;
                    case 'A_SF':
                        this.inverter.inverterASf = point.value;
                        break;
                    case 'PPVphAB':
                        this.inverter.inverterPpvphab = point.value;
                        break;
                    case 'PPVphBC':
                        this.inverter.inverterPpvphbc = point.value;
                        break;
                    case 'PPVphCA':
                        this.inverter.inverterPpvphca = point.value;
                        break;
                    case 'PhVphA':
                        this.inverter.inverterPhvpha = point.value;
                        break;
                    case 'PhVphB':
                        this.inverter.inverterPhvphb = point.value;
                        break;
                    case "PhVphC":
                        this.inverter.inverterPhvphc = point.value;
                        break;
                    case "V_SF":
                        this.inverter.inverterVSf = point.value;
                        break;
                    case "W":
                        this.inverter.inverterW = point.value;
                        break;
                    case "W_SF":
                        // Do something for W_SF
                        this.inverter.inverterWSf = point.value;
                        break;
                    case "Hz":
                        // Do something for Hz
                        this.inverter.inverterHz = point.value;
                        break;
                    case "Hz_SF":
                        // Do something for Hz_SF
                        this.inverter.inverterHzSf = point.value;
                        break;
                    case "VA":
                        // Do something for VA
                        this.inverter.inverterVa = point.value;
                        break;
                    case "VA_SF":
                        // Do something for VA_SF
                        this.inverter.inverterVaSf = point.value;
                        break;
                    case "VAr":
                        // Do something for VAr
                        this.inverter.inverterVar = point.value;
                        break;
                    case "VAr_SF":
                        // Do something for VAr_SF
                        this.inverter.inverterVarSf = point.value;
                        break;
                    case "PF":
                        // Do something for PF
                        this.inverter.inverterPf = point.value;
                        break;
                    case "PF_SF":
                        // Do something for PF_SF
                        this.inverter.inverterPfSf = point.value;
                        break;
                    case "WH":
                        // Do something for WH
                        this.inverter.inverterWh = point.value;
                        break;
                    case "WH_SF":
                        // Do something for WH_SF
                        this.inverter.inverterWhSf = point.value;
                        break;
                    case "DCA":
                        // Do something for DCA
                        this.inverter.inverterDca = point.value;
                        break;
                    case "DCA_SF":
                        // Do something for DCA_SF
                        this.inverter.inverterDcaSf = point.value;
                        break;
                    case "DCV":
                        // Do something for DCV
                        this.inverter.inverterDcv = point.value;
                        break;
                    case "DCV_SF":
                        // Do something for DCV_SF
                        this.inverter.inverterDcvSf = point.value;
                        break;
                    case "DCW":
                        // Do something for DCW
                        this.inverter.inverterDcw = point.value;
                        break;
                    case "DCW_SF":
                        // Do something for DCW_SF
                        this.inverter.inverterDcwSf = point.value;
                        break;
                    case "TmpCab":
                        // Do something for TmpCab
                        this.inverter.inverterTmpcab = point.value;
                        break;
                    case "TmpSnk":
                        // Do something for TmpSnk
                        this.inverter.inverterTmpsnk = point.value;
                        break;
                    case "TmpTrns":
                        // Do something for TmpTrns
                        this.inverter.inverterTmptrns = point.value;
                        break;
                    case "TmpOt":
                        // Do something for TmpOt
                        this.inverter.inverterTmpot = point.value;
                        break;
                    case "Tmp_SF":
                        // Do something for Tmp_SF
                        this.inverter.inverterTmpSf = point.value;
                        break;
                    case "St":
                        // Do something for St
                        this.inverter.inverterSt = point.value;
                        break;
                    case "StVnd":
                        // Do something for StVnd
                        this.inverter.inverterStvnd = point.value;
                        break;
                    case "Evt1":
                        // Do something for Evt1
                        this.inverter.inverterEvt1 = point.value;
                        break;
                    case "Evt2":
                        // Do something for Evt2
                        this.inverter.inverterEvt2 = point.value;
                        break;
                    case "EvtVnd1":
                        // Do something for EvtVnd1
                        this.inverter.inverterEvtvnd1 = point.value;
                        break;
                    case "EvtVnd2":
                        // Do something for EvtVnd2
                        this.inverter.inverterEvtvnd2 = point.value;
                        break;
                    case "EvtVnd3":
                        // Do something for EvtVnd3
                        this.inverter.inverterEvtvnd3 = point.value;
                        break;
                    case "EvtVnd4":
                        // Do something for EvtVnd4
                        this.inverter.inverterEvtvnd4 = point.value;
                        break;
                    default:
                        console.log('Unexpected point.name:', point.name);
                        break;
                }
            });
            this.finishProccessingInverterData.inverterSingle = true;
        },
        handleInverterthreeGroup(points) {
            // Show loading spinner
            this.loading = true;
            points.forEach(point => {
                switch (point.name) {
                    case 'ID':
                        this.inverter.inverterInverterId = point.value;
                        break;
                    case 'L':
                        this.inverter.inverterL = point.value;
                        break;
                    case 'A':
                        this.inverter.inverterA = point.value;
                        break;
                    case 'AphA':
                        this.inverter.inverterApha = point.value;
                        break;
                    case 'AphB':
                        this.inverter.inverterAphb = point.value;
                        break;
                    case 'AphC':
                        this.inverter.inverterAphc = point.value;
                        break;
                    case 'A_SF':
                        this.inverter.inverterASf = point.value;
                        break;
                    case 'PPVphAB':
                        this.inverter.inverterPpvphab = point.value;
                        break;
                    case 'PPVphBC':
                        this.inverter.inverterPpvphbc = point.value;
                        break;
                    case 'PPVphCA':
                        this.inverter.inverterPpvphca = point.value;
                        break;
                    case 'PhVphA':
                        this.inverter.inverterPhvpha = point.value;
                        break;
                    case 'PhVphB':
                        this.inverter.inverterPhvphb = point.value;
                        break;
                    case "PhVphC":
                        this.inverter.inverterPhvphc = point.value;
                        break;
                    case "V_SF":
                        this.inverter.inverterVSf = point.value;
                        break;
                    case "W":
                        this.inverter.inverterW = point.value;
                        break;
                    case "W_SF":
                        // Do something for W_SF
                        this.inverter.inverterWSf = point.value;
                        break;
                    case "Hz":
                        // Do something for Hz
                        this.inverter.inverterHz = point.value;
                        break;
                    case "Hz_SF":
                        // Do something for Hz_SF
                        this.inverter.inverterHzSf = point.value;
                        break;
                    case "VA":
                        // Do something for VA
                        this.inverter.inverterVa = point.value;
                        break;
                    case "VA_SF":
                        // Do something for VA_SF
                        this.inverter.inverterVaSf = point.value;
                        break;
                    case "VAr":
                        // Do something for VAr
                        this.inverter.inverterVar = point.value;
                        break;
                    case "VAr_SF":
                        // Do something for VAr_SF
                        this.inverter.inverterVarSf = point.value;
                        break;
                    case "PF":
                        // Do something for PF
                        this.inverter.inverterPf = point.value;
                        break;
                    case "PF_SF":
                        // Do something for PF_SF
                        this.inverter.inverterPfSf = point.value;
                        break;
                    case "WH":
                        // Do something for WH
                        this.inverter.inverterWh = point.value;
                        break;
                    case "WH_SF":
                        // Do something for WH_SF
                        this.inverter.inverterWhSf = point.value;
                        break;
                    case "DCA":
                        // Do something for DCA
                        this.inverter.inverterDca = point.value;
                        break;
                    case "DCA_SF":
                        // Do something for DCA_SF
                        this.inverter.inverterDcaSf = point.value;
                        break;
                    case "DCV":
                        // Do something for DCV
                        this.inverter.inverterDcv = point.value;
                        break;
                    case "DCV_SF":
                        // Do something for DCV_SF
                        this.inverter.inverterDcvSf = point.value;
                        break;
                    case "DCW":
                        // Do something for DCW
                        this.inverter.inverterDcw = point.value;
                        break;
                    case "DCW_SF":
                        // Do something for DCW_SF
                        this.inverter.inverterDcwSf = point.value;
                        break;
                    case "TmpCab":
                        // Do something for TmpCab
                        this.inverter.inverterTmpcab = point.value;
                        break;
                    case "TmpSnk":
                        // Do something for TmpSnk
                        this.inverter.inverterTmpsnk = point.value;
                        break;
                    case "TmpTrns":
                        // Do something for TmpTrns
                        this.inverter.inverterTmptrns = point.value;
                        break;
                    case "TmpOt":
                        // Do something for TmpOt
                        this.inverter.inverterTmpot = point.value;
                        break;
                    case "Tmp_SF":
                        // Do something for Tmp_SF
                        this.inverter.inverterTmpSf = point.value;
                        break;
                    case "St":
                        // Do something for St
                        this.inverter.inverterSt = point.value;
                        break;
                    case "StVnd":
                        // Do something for StVnd
                        this.inverter.inverterStvnd = point.value;
                        break;
                    case "Evt1":
                        // Do something for Evt1
                        this.inverter.inverterEvt1 = point.value;
                        break;
                    case "Evt2":
                        // Do something for Evt2
                        this.inverter.inverterEvt2 = point.value;
                        break;
                    case "EvtVnd1":
                        // Do something for EvtVnd1
                        this.inverter.inverterEvtvnd1 = point.value;
                        break;
                    case "EvtVnd2":
                        // Do something for EvtVnd2
                        this.inverter.inverterEvtvnd2 = point.value;
                        break;
                    case "EvtVnd3":
                        // Do something for EvtVnd3
                        this.inverter.inverterEvtvnd3 = point.value;
                        break;
                    case "EvtVnd4":
                        // Do something for EvtVnd4
                        this.inverter.inverterEvtvnd4 = point.value;
                        break;
                    default:
                        console.log('Unexpected point.name:', point.name);
                        break;
                }
            });
            this.finishProccessingInverterData.inverterThree = true;
        },
        handleNameplateGroup(points) {
            // Show loading spinner
            this.loading = true;
            points.forEach(point => {
                switch (point.name) {
                    case 'ID':
                        this.inverter.nameplateNameplateId = point.value;
                        break;
                    case 'L':
                        this.inverter.nameplateL = point.value;
                        break;
                    case 'DERTyp':
                        this.inverter.nameplateDertyp = point.value;
                        break;
                    case 'WRtg':
                        this.inverter.nameplateWrtg = point.value;
                        break;
                    case 'WRtg_SF':
                        this.inverter.nameplateWrtgSf = point.value;
                        break;
                    case 'VARtg':
                        this.inverter.nameplateVartg = point.value;
                        break;
                    case 'VARtg_SF':
                        this.inverter.nameplateVartgSf = point.value;
                        break;
                    case 'VArRtgQ1':
                        this.inverter.nameplateVarrtgq1 = point.value;
                        break;
                    case 'VArRtgQ2':
                        this.inverter.nameplateVarrtgq2 = point.value;
                        break;
                    case 'VArRtgQ3':
                        this.inverter.nameplateVarrtgq3 = point.value;
                        break;
                    case 'VArRtgQ4':
                        this.inverter.nameplateVarrtgq4 = point.value;
                        break;
                    case 'VArRtg_SF':
                        this.inverter.nameplateVarrtgSf = point.value;
                        break;
                    case 'ARtg':
                        this.inverter.nameplateArtg = point.value;
                        break;
                    case 'ARtg_SF':
                        this.inverter.nameplateArtgSf = point.value;
                        break;
                    case 'PFRtgQ1':
                        this.inverter.nameplatePfrtgq1 = point.value;
                        break;
                    case 'PFRtgQ2':
                        this.inverter.nameplatePfrtgq2 = point.value;
                        break;
                    case 'PFRtgQ3':
                        this.inverter.nameplatePfrtgq3 = point.value;
                        break;
                    case 'PFRtgQ4':
                        this.inverter.nameplatePfrtgq4 = point.value;
                        break;
                    case 'PFRtg_SF':
                        this.inverter.nameplatePfrtgSf = point.value;
                        break;
                    case 'WHRtg':
                        this.inverter.nameplateWhrtg = point.value;
                        break;
                    case 'WHRtg_SF':
                        this.inverter.nameplateWhrtgSf = point.value;
                        break;
                    case 'AhrRtg':
                        this.inverter.nameplateAhrrtg = point.value;
                        break;
                    case 'AhrRtg_SF':
                        this.inverter.nameplateAhrrtgSf = point.value;
                        break;
                    case 'MaxChaRte':
                        this.inverter.nameplateMaxcharte = point.value;
                        break;
                    case 'MaxChaRte_SF':
                        this.inverter.nameplateMaxcharteSf = point.value;
                        break;
                    case 'MaxDisChaRte':
                        this.inverter.nameplateMaxdischarte = point.value;
                        break;
                    case 'MaxDisChaRte_SF':
                        this.inverter.nameplateMaxdischarteSf = point.value;
                        break;
                    case 'Pad':
                        this.inverter.nameplatePad = point.value;
                        break;
                    default:
                        console.log('Unexpected point.name:', point.name);
                        break;
                }
            });
            this.finishProccessingInverterData.nameplate = true;
        },
        handleSettingsGroup(points) {
            // Show loading spinner
            this.loading = true;
            points.forEach(point => {
                switch (point.name) {
                    case 'ID':
                        this.inverter.settingsSettingsId = point.value;
                        break;
                    case 'L':
                        this.inverter.settingsL = point.value;
                        break;
                    case 'WMax':
                        // code for WMax
                        this.inverter.settingsWmax = point.value;
                        break;
                    case 'VRef':
                        // code for VRef
                        this.inverter.settingsVref = point.value;
                        break;
                    case 'VRefOfs':
                        // code for VRefOfs
                        this.inverter.settingsVrefofs = point.value;
                        break;
                    case 'VMax':
                        // code for VMax
                        this.inverter.settingsVmax = point.value;
                        break;
                    case 'VMin':
                        // code for VMin
                        this.inverter.settingsVmin = point.value;
                        break;
                    case 'VAMax':
                        // code for VAMax
                        this.inverter.settingsVamax = point.value;
                        break;
                    case 'VArMaxQ1':
                        // code for VArMaxQ1
                        this.inverter.settingsVarmaxq1 = point.value;
                        break;
                    case 'VArMaxQ2':
                        // code for VArMaxQ2
                        this.inverter.settingsVarmaxq2 = point.value;
                        break;
                    case 'VArMaxQ3':
                        // code for VArMaxQ3
                        this.inverter.settingsVarmaxq3 = point.value;
                        break;
                    case 'VArMaxQ4':
                        // code for VArMaxQ4
                        this.inverter.settingsVarmaxq4 = point.value;
                        break;
                    case 'WGra':
                        // code for WGra
                        this.inverter.settingsWgra = point.value;
                        break;
                    case 'PFMinQ1':
                        // code for PFMinQ1
                        this.inverter.settingsPfminq1 = point.value;
                        break;
                    case 'PFMinQ2':
                        // code for PFMinQ2
                        this.inverter.settingsPfminq2 = point.value;
                        break;
                    case 'PFMinQ3':
                        // code for PFMinQ3
                        this.inverter.settingsPfminq3 = point.value;
                        break;
                    case 'PFMinQ4':
                        // code for PFMinQ4
                        this.inverter.settingsPfminq4 = point.value;
                        break;
                    case 'VArAct':
                        // code for VArAct
                        this.inverter.settingsVaract = point.value;
                        break;
                    case 'ClcTotVA':
                        // code for ClcTotVA
                        this.inverter.settingsClctotva = point.value;
                        break;
                    case 'MaxRmpRte':
                        // code for MaxRmpRte
                        this.inverter.settingsMaxrmprte = point.value;
                        break;
                    case 'ECPNomHz':
                        // code for ECPNomHz
                        this.inverter.settingsEcpnomhz = point.value;
                        break;
                    case 'ConnPh':
                        // code for ConnPh
                        this.inverter.settingsConnph = point.value;
                        break;
                    case 'WMax_SF':
                        // code for WMax_SF
                        this.inverter.settingsWmaxSf = point.value;
                        break;
                    case 'VRef_SF':
                        // code for VRef_SF
                        this.inverter.settingsVrefSf = point.value;
                        break;
                    case 'VRefOfs_SF':
                        // code for VRefOfs_SF
                        this.inverter.settingsVrefofsSf = point.value;
                        break;
                    case 'VMinMax_SF':
                        // code for VMinMax_SF
                        this.inverter.settingsVminmaxSf = point.value;
                        break;
                    case 'VAMax_SF':
                        // code for VAMax_SF
                        this.inverter.settingsVamaxSf = point.value;
                        break;
                    case 'VArMax_SF':
                        // code for VArMax_SF
                        this.inverter.settingsVarmaxSf = point.value;
                        break;
                    case 'WGra_SF':
                        // code for WGra_SF
                        this.inverter.settingsWgraSf = point.value;
                        break;
                    case 'PFMin_SF':
                        // code for PFMin_SF
                        this.inverter.settingsPfminSf = point.value;
                        break;
                    case 'MaxRmpRte_SF':
                        // code for MaxRmpRte_SF
                        this.inverter.settingsMaxrmprteSf = point.value;
                        break;
                    case 'ECPNomHz_SF':
                        // code for ECPNomHz_SF
                        this.inverter.settingsEcpnomhzSf = point.value;
                        break;
                    default:
                        console.log('Unexpected point.name:', point.name);
                        break;
                }
            });
            this.finishProccessingInverterData.settings = true;
        },
        handleStatusGroup(points) {
            // Show loading spinner
            this.loading = true;
            points.forEach(point => {
                switch (point.name) {
                    case 'ID':
                        this.inverter.statusStatusId = point.value;
                        break;
                    case 'L':
                        this.inverter.statusL = point.value;
                        break;
                    case 'PVConn':
                        this.inverter.statusPvconn = point.value;
                        break;
                    case 'StorConn':
                        this.inverter.statusStorconn = point.value;
                        break;
                    case 'ECPConn':
                        this.inverter.statusEcpconn = point.value;
                        break;
                    case 'ActWh':
                        this.inverter.statusActwh = point.value;
                        break;
                    case 'ActVAh':
                        this.inverter.statusActvah = point.value;
                        break;
                    case 'ActVArhQ1':
                        this.inverter.statusActvarhq1 = point.value;
                        break;
                    case 'ActVArhQ2':
                        this.inverter.statusActvarhq2 = point.value;
                        break;
                    case 'ActVArhQ3':
                        this.inverter.statusActvarhq3 = point.value;
                        break;
                    case 'ActVArhQ4':
                        this.inverter.statusActvarhq4 = point.value;
                        break;
                    case 'VArAval':
                        this.inverter.statusVaraval = point.value;
                        break;
                    case 'VArAval_SF':
                        this.inverter.statusVaravalSf = point.value;
                        break;
                    case 'WAval':
                        this.inverter.statusWaval = point.value;
                        break;
                    case 'WAval_SF':
                        this.inverter.statusWavalSf = point.value;
                        break;
                    case 'StSetLimMsk':
                        this.inverter.statusStsetlimmsk = point.value;
                        break;
                    case 'StActCtl':
                        this.inverter.statusStactctl = point.value;
                        break;
                    case 'TmSrc':
                        this.inverter.statusTmsrc = point.name;
                        break;
                    case 'Tms':
                        this.inverter.statusTms = point.value;
                        break;
                    case 'RtSt':
                        this.inverter.statusRtst = point.value;
                        break;
                    case 'Ris':
                        this.inverter.statusRis = point.value;
                        break;
                    case 'Ris_SF':
                        this.inverter.statusRisSf = point.value;
                        break;
                    default:
                        console.log('Unexpected point.name:', point.name);
                        break;
                }
            });
            this.finishProccessingInverterData.status = true;
        },
        handleControlsGroup(points) {
            // Show loading spinner
            this.loading = true;
            points.forEach(point => {
                switch (point.name) {
                    case 'ID':
                        this.inverter.controlsControlsId = point.value;
                        break;
                    case 'L':
                        this.inverter.controlsL = point.value;
                        break;
                    case 'Conn_WinTms':
                        this.inverter.controlsConnWintms = point.value;
                        break;
                    case 'Conn_RvrtTms':
                        this.inverter.controlsConnRvrttms = point.value;
                        break;
                    case 'Conn':
                        this.inverter.controlsConn = point.value;
                        break;
                    case 'WMaxLimPct':
                        this.inverter.controlsWmaxlimpct = point.value;
                        break;
                    case 'WMaxLimPct_WinTms':
                        this.inverter.controlsWmaxlimpctWintms = point.value;
                        break;
                    case 'WMaxLimPct_RvrtTms':
                        this.inverter.controlsWmaxlimpctRvrttms = point.value;
                        break;
                    case 'WMaxLimPct_RmpTms':
                        this.inverter.controlsWmaxlimpctRmptms = point.value;
                        break;
                    case 'WMaxLim_Ena':
                        this.inverter.controlsWmaxlimEna = point.value;
                        break;
                    case 'OutPFSet':
                        this.inverter.controlsOutpfset = point.value;
                        break;
                    case 'OutPFSet_WinTms':
                        this.inverter.controlsOutpfsetWintms = point.value;
                        break;
                    case 'OutPFSet_RvrtTms':
                        this.inverter.controlsOutpfsetRvrttms = point.value;
                        break;
                    case 'OutPFSet_RmpTms':
                        this.inverter.controlsOutpfsetRmptms = point.value;
                        break;
                    case 'OutPFSet_Ena':
                        this.inverter.controlsOutpfsetEna = point.value;
                        break;
                    case 'VArWMaxPct':
                        this.inverter.controlsVarwmaxpct = point.value;
                        break;
                    case 'VArMaxPct':
                        this.inverter.controlsVarmaxpct = point.value;
                        break;
                    case 'VArAvalPct':
                        this.inverter.controlsVaravalpct = point.value;
                        break;
                    case 'VArPct_WinTms':
                        this.inverter.controlsVarpctWintms = point.value;
                        break;
                    case 'VArPct_RvrtTms':
                        this.inverter.controlsVarpctRvrttms = point.value;
                        break;
                    case 'VArPct_RmpTms':
                        this.inverter.controlsVarpctRmptms = point.value;
                        break;
                    case 'VArPct_Mod':
                        this.inverter.controlsVarpctMod = point.value;
                        break;
                    case 'VArPct_Ena':
                        this.inverter.controlsVarpctEna = point.value;
                        break;
                    case 'WMaxLimPct_SF':
                        this.inverter.controlsWmaxlimpctSf = point.value;
                        break;
                    case 'OutPFSet_SF':
                        this.inverter.controlsOutpfsetSf = point.value;
                        break;
                    case 'VArPct_SF':
                        this.inverter.controlsVarpctSf = point.value;
                        break;
                    default:
                        console.log('Unexpected point.name:', point.name);
                        break;
                }
            });
            this.finishProccessingInverterData.controls = true;
        },
        handleVoltVarCurveGroup(points, group) {
            // Show loading spinner
            this.loading = true;
            group.groups[0].points.forEach(point => {
                switch (point.name) {
                    case 'ID':
                        this.inverter.voltVarCurveVoltVarId = 126;
                        break;
                    case 'ActPt':
                        this.inverter.voltVarCurveActpt = point.value;
                        break;
                    case 'DeptRef':
                        this.inverter.voltVarCurveDeptref = point.value;
                        break;
                    case 'V1':
                        this.inverter.voltVarCurveV1 = point.value;
                        break;
                    case 'VAr1':
                        this.inverter.voltVarCurveVar1 = point.value;
                        break;
                    case 'V2':
                        this.inverter.voltVarCurveV2 = point.value;
                        break;
                    case 'VAr2':
                        this.inverter.voltVarCurveVar2 = point.value;
                        break;
                    case 'V3':
                        this.inverter.voltVarCurveV3 = point.value;
                        break;
                    case 'VAr3':
                        this.inverter.voltVarCurveVar3 = point.value;
                        break;
                    case 'V4':
                        this.inverter.voltVarCurveV4 = point.value;
                        break;
                    case 'VAr4':
                        this.inverter.voltVarCurveVar4 = point.value;
                        break;
                    case 'V5':
                        this.inverter.voltVarCurveV5 = point.value;
                        break;
                    case 'VAr5':
                        this.inverter.voltVarCurveVar5 = point.value;
                        break;
                    case 'V6':
                        this.inverter.voltVarCurveV6 = point.value;
                        break;
                    case 'VAr6':
                        this.inverter.voltVarCurveVar6 = point.value;
                        break;
                    case 'V7':
                        this.inverter.voltVarCurveV7 = point.value;
                        break;
                    case 'VAr7':
                        this.inverter.voltVarCurveVar7 = point.value;
                        break;
                    case 'V8':
                        this.inverter.voltVarCurveV8 = point.value;
                        break;
                    case 'VAr8':
                        this.inverter.voltVarCurveVar8 = point.value;
                        break;
                    case 'V9':
                        this.inverter.voltVarCurveV9 = point.value;
                        break;
                    case 'VAr9':
                        this.inverter.voltVarCurveVar9 = point.value;
                        break;
                    case 'V10':
                        this.inverter.voltVarCurveV10 = point.value;
                        break;
                    case 'VAr10':
                        this.inverter.voltVarCurveVar10 = point.value;
                        break;
                    case 'V11':
                        this.inverter.voltVarCurveV11 = point.value;
                        break;
                    case 'VAr11':
                        this.inverter.voltVarCurveVar11 = point.value;
                        break;
                    case 'V12':
                        this.inverter.voltVarCurveV12 = point.value;
                        break;
                    case 'VAr12':
                        this.inverter.voltVarCurveVar12 = point.value;
                        break;
                    case 'V13':
                        this.inverter.voltVarCurveV13 = point.value;
                        break;
                    case 'VAr13':
                        this.inverter.voltVarCurveVar13 = point.value;
                        break;
                    case 'V14':
                        this.inverter.voltVarCurveV14 = point.value;
                        break;
                    case 'VAr14':
                        this.inverter.voltVarCurveVar14 = point.value;
                        break;
                    case 'V15':
                        this.inverter.voltVarCurveV15 = point.value;
                        break;
                    case 'VAr15':
                        this.inverter.voltVarCurveVar15 = point.value;
                        break;
                    case 'V16':
                        this.inverter.voltVarCurveV16 = point.value;
                        break;
                    case 'VAr16':
                        this.inverter.voltVarCurveVar16 = point.value;
                        break;
                    case 'V17':
                        this.inverter.voltVarCurveV17 = point.value;
                        break;
                    case 'VAr17':
                        this.inverter.voltVarCurveVar17 = point.value;
                        break;
                    case 'V18':
                        this.inverter.voltVarCurveV18 = point.value;
                        break;
                    case 'VAr18':
                        this.inverter.voltVarCurveVar18 = point.value;
                        break;
                    case 'V19':
                        this.inverter.voltVarCurveV19 = point.value;
                        break;
                    case 'VAr19':
                        this.inverter.voltVarCurveVar19 = point.value;
                        break;
                    case 'V20':
                        this.inverter.voltVarCurveV20 = point.value;
                        break;
                    case 'VAr20':
                        this.inverter.voltVarCurveVar20 = point.value;
                        break;
                    case 'CrvNam':
                        this.inverter.voltVarCurveCrvnam = point.name;
                        break;
                    case 'RmpTms':
                        this.inverter.voltVarCurveRmptms = point.value;
                        break;
                    case 'RmpDecTmm':
                        this.inverter.voltVarCurveRmpdectmm = point.value;
                        break;
                    case 'RmpIncTmm':
                        this.inverter.voltVarCurveRmpinctmm = point.value;
                        break;
                    case 'ReadOnly':
                        this.inverter.voltVarCurveReadonly = point.value;
                        break;
                    default:
                        console.log('Unexpected point.name:', point.name);
                        break;
                }
            });
            this.finishProccessingInverterData.voltVar = true;
        },
        handleFreqWattParamGroup(points) {
            // Show loading spinner
            this.loading = true;
            points.forEach(point => {
                switch (point.name) {
                    case 'ID':
                        this.inverter.freqWattParamFreqWattParamId = point.value;
                        break;
                    case 'L':
                        this.inverter.freqWattParamL = point.value;
                        break;
                    case 'WGra':
                        this.inverter.freqWattParamWgra = point.value;
                        break;
                    case 'HzStr':
                        this.inverter.freqWattParamHzstr = point.value;
                        break;
                    case 'HzStop':
                        this.inverter.freqWattParamHzstop = point.value;
                        break;
                    case 'HysEna':
                        this.inverter.freqWattParamHysena = point.value;
                        break;
                    case 'ModEna':
                        this.inverter.freqWattParamModena = point.value;
                        break;
                    case 'HzStopWGra':
                        this.inverter.freqWattParamHzstopwgra = point.value;
                        break;
                    case 'WGra_SF':
                        this.inverter.freqWattParamWgraSf = point.value;
                        break;
                    case 'HzStrStop_SF':
                        this.inverter.freqWattParamHzstrstopSf = point.value;
                        break;
                    case 'RmpIncDec_SF':
                        this.inverter.freqWattParamRmpincdecSf = point.value;
                        break;
                    case 'Pad':
                        this.inverter.freqWattParamPad = point.value;
                        break;
                    default:
                        console.log('Unexpected point.name:', point.name);
                        break;
                }
            });

            this.finishProccessingInverterData.freqWattParam = true;
        },
        handleReactiveCurrentGroup(points) {
            // Show loading spinner
            this.loading = true;
            points.forEach(point => {
                switch (point.name) {
                    case 'ID':
                        this.inverter.reactiveCurrentReactiveCurrentId = point.value;
                        break;
                    case 'L':
                        this.inverter.reactiveCurrentL = point.value;
                        break;
                    case 'ArGraMod':
                        this.inverter.reactiveCurrentArgramod = point.value;
                        break;
                    case 'ArGraSag':
                        this.inverter.reactiveCurrentArgrasag = point.value;
                        break;
                    case 'ArGraSwell':
                        this.inverter.reactiveCurrentArgraswell = point.value;
                        break;
                    case 'ModEna':
                        this.inverter.reactiveCurrentModena = point.value;
                        break;
                    case 'FilTms':
                        this.inverter.reactiveCurrentFiltms = point.value;
                        break;
                    case 'DbVMin':
                        this.inverter.reactiveCurrentDbvmin = point.value;
                        break;
                    case 'DbVMax':
                        this.inverter.reactiveCurrentDbvmax = point.value;
                        break;
                    case 'BlkZnV':
                        this.inverter.reactiveCurrentBlkznv = point.value;
                        break;
                    case 'HysBlkZnV':
                        this.inverter.reactiveCurrentHysblkznv = point.value;
                        break;
                    case 'BlkZnTmms':
                        this.inverter.reactiveCurrentBlkzntmms = point.value;
                        break;
                    case 'HoldTmms':
                        this.inverter.reactiveCurrentHoldtmms = point.value;
                        break;
                    case 'ArGra_SF':
                        this.inverter.reactiveCurrentArgraSf = point.value;
                        break;
                    case 'VRefPct_SF':
                        this.inverter.reactiveCurrentVrefpctSf = point.value;
                        break;
                    case 'Pad':
                        this.inverter.reactiveCurrentPad = point.value;
                        break;
                    default:
                        console.log('Unexpected point.name:', point.name);
                        break;
                }
            });

            this.finishProccessingInverterData.reactiveCurrent = true;
        },
        handleMpptGroup(points, group) {
            this.loading = true;
            group.groups[0].points.forEach(point => {
                switch (point.name) {
                    case 'ID':
                        this.inverter.mpptModuleMpptId = point.value;
                        break;
                    case 'IDStr':
                        this.inverter.mpptModuleIdstr = point.name;
                        break;
                    case 'DCA':
                        this.inverter.mpptModuleDca = point.value;
                        break;
                    case 'DCV':
                        this.inverter.mpptModuleDcv = point.value;
                        break;
                    case 'DCW':
                        this.inverter.mpptModuleDcw = point.value;
                        break;
                    case 'DCWH':
                        this.inverter.mpptModuleDcwh = point.value;
                        break;
                    case 'Tms':
                        this.inverter.mpptModuleTms = point.value;
                        break;
                    case 'Tmp':
                        this.inverter.mpptModuleTmp = point.value;
                        break;
                    case 'DCSt':
                        this.inverter.mpptModuleDcst = point.value;
                        break;
                    case 'DCEvt':
                        this.inverter.mpptModuleDcevt = point.value;
                        break;
                    default:
                        console.log('Unexpected point.name:', point.name);
                        break;
                }
            });

            points.forEach(point => {
                switch (point.name) {
                    case 'ID':
                        this.inverter.mpptMpptId = point.value;
                        break;
                    case 'L':
                        this.inverter.mpptL = point.value;
                        break;
                    case 'DCA_SF':
                        this.inverter.mpptDcaSf = point.value;
                        break;
                    case 'DCV_SF':
                        this.inverter.mpptDcvSf = point.value;
                        break;
                    case 'DCW_SF':
                        this.inverter.mpptDcwSf = point.value;
                        break;
                    case 'DCWH_SF':
                        this.inverter.mpptDcwhSf = point.value;
                        break;
                    case 'Evt':
                        this.inverter.mpptEvt = point.value;
                        break;
                    case 'N':
                        this.inverter.mpptN = point.value;
                        break;
                    case 'TmsPer':
                        this.inverter.mpptTmsper = point.value;
                        break;
                    default:
                        console.log('Unexpected point.name:', point.name);
                        break;
                }
            })

            this.finishProccessingInverterData.mppt = true;
        },
        handleVoltWattGroup(points, group) {
            // Show loading spinner
            this.loading = true;
            group.groups[0].points.forEach(point => {
                switch (point.name) {
                    case 'ID':
                        this.inverter.voltWattCurveVoltWattId = point.value;
                        break;
                    case 'ActPt':
                        this.inverter.voltWattCurveActpt = point.value;
                        break;
                    case 'DeptRef':
                        this.inverter.voltWattCurveDeptref = point.value;
                        break;
                    case 'V1':
                        this.inverter.voltWattCurveV1 = point.value;
                        break;
                    case 'W1':
                        this.inverter.voltWattCurveW1 = point.value;
                        break;
                    case 'V2':
                        this.inverter.voltWattCurveV2 = point.value;
                        break;
                    case 'W2':
                        this.inverter.voltWattCurveW2 = point.value;
                        break;
                    case 'V3':
                        this.inverter.voltWattCurveV3 = point.value;
                        break;
                    case 'W3':
                        this.inverter.voltWattCurveW3 = point.value;
                        break;
                    case 'V4':
                        this.inverter.voltWattCurveV4 = point.value;
                        break;
                    case 'W4':
                        this.inverter.voltWattCurveW4 = point.value;
                        break;
                    case 'V5':
                        this.inverter.voltWattCurveV5 = point.value;
                        break;
                    case 'W5':
                        this.inverter.voltWattCurveW5 = point.value;
                        break;
                    case 'V6':
                        this.inverter.voltWattCurveV6 = point.value;
                        break;
                    case 'W6':
                        this.inverter.voltWattCurveV6 = point.value;
                        break;
                    case 'V7':
                        this.inverter.voltWattCurveV7 = point.value;
                        break;
                    case 'W7':
                        this.inverter.voltWattCurveW7 = point.value;
                        break;
                    case 'V8':
                        this.inverter.voltWattCurveV8 = point.value;
                        break;
                    case 'W8':
                        this.inverter.voltWattCurveW8 = point.value;
                        break;
                    case 'V9':
                        this.inverter.voltWattCurveV9 = point.value;
                        break;
                    case 'W9':
                        this.inverter.voltWattCurveW9 = point.value;
                        break;
                    case 'V10':
                        this.inverter.voltWattCurveV10 = point.value;
                        break;
                    case 'W10':
                        this.inverter.voltWattCurveW10 = point.value;
                        break;
                    case 'V11':
                        this.inverter.voltWattCurveV11 = point.value;
                        break;
                    case 'W11':
                        this.inverter.voltWattCurveW11 = point.value;
                        break;
                    case 'V12':
                        this.inverter.voltWattCurveV12 = point.value;
                        break;
                    case 'W12':
                        this.inverter.voltWattCurveW12 = point.value;
                        break;
                    case 'V13':
                        this.inverter.voltWattCurveV13 = point.value;
                        break;
                    case 'W13':
                        this.inverter.voltWattCurveW13 = point.value;
                        break;
                    case 'V14':
                        this.inverter.voltWattCurveV14 = point.value;
                        break;
                    case 'W14':
                        this.inverter.voltWattCurveW14 = point.value;
                        break;
                    case 'V15':
                        this.inverter.voltWattCurveV15 = point.value;
                        break;
                    case 'W15':
                        this.inverter.voltWattCurveW15 = point.value;
                        break;
                    case 'V16':
                        this.inverter.voltWattCurveV16 = point.value;
                        break;
                    case 'W16':
                        this.inverter.voltWattCurveW16 = point.value;
                        break;
                    case 'V17':
                        this.inverter.voltWattCurveV17 = point.value;
                        break;
                    case 'W17':
                        this.inverter.voltWattCurveW17 = point.value;
                        break;
                    case 'V18':
                        this.inverter.voltWattCurveV18 = point.value;
                        break;
                    case 'W18':
                        this.inverter.voltWattCurveW18 = point.value;
                        break;
                    case 'V19':
                        this.inverter.voltWattCurveV19 = point.value;
                        break;
                    case 'W19':
                        this.inverter.voltWattCurveW19 = point.value;
                        break;
                    case 'V20':
                        this.inverter.voltWattCurveV20 = point.value;
                        break;
                    case 'W20':
                        this.inverter.voltWattCurveW20 = point.value;
                        break;
                    case 'CrvNam':
                        this.inverter.voltWattCurveCrvnam = point.name;
                        break;
                    case 'RmpPt1Tms':
                        this.inverter.voltWattCurveRmppt1tms = point.value;
                        break;
                    case 'RmpDecTmm':
                        this.inverter.voltWattCurveRmpdectmm = point.value;
                        break;
                    case 'RmpIncTmm':
                        this.inverter.voltWattCurveRmpinctmm = point.value;
                        break;
                    case 'ReadOnly':
                        this.inverter.voltWattCurveReadonly = point.value;
                        break;
                    default:
                        console.log('Unexpected point.name:', point.name);
                        break;
                }
            });

            points.forEach(point => {
                switch (point.name) {
                    case 'ID':
                        this.inverter.voltWattVoltWattId = point.value;
                        break;
                    case 'L':
                        this.inverter.voltWattL = point.value;
                        break;
                    case 'ActCrv':
                        this.inverter.voltWattActcrv = point.value;
                        break;
                    case 'ModEna':
                        this.inverter.voltWattModena = point.value;
                        break;
                    case 'WinTms':
                        this.inverter.voltWattWintms = point.value;
                        break;
                    case 'RvrtTms':
                        this.inverter.voltWattRvrttms = point.value;
                        break;
                    case 'RmpTms':
                        this.inverter.voltWattRmptms = point.value;
                        break;
                    case 'NCrv':
                        this.inverter.voltWattNcrv = point.value;
                        break;
                    case 'NPt':
                        this.inverter.voltWattNpt = point.value;
                        break;
                    case 'V_SF':
                        this.inverter.voltWattVSf = point.value;
                        break;
                    case 'DeptRef_SF':
                        this.inverter.voltWattDeptrefSf = point.value;
                        break;
                    case 'RmpIncDec_SF':
                        this.inverter.voltWattRmpincdecSf = point.value;
                        break;
                    default:
                        console.log('Unexpected point.name:', point.name);
                        break;
                }
            })
            this.finishProccessingInverterData.voltWatt = true;
        },
        async addInvertedToDb() {

            for (let key in this.inverter) {
                // console.log("DEBUG 400: POINT => ", this.inverter[key])
                if (this.inverter[key] === undefined) {
                    this.inverter[key] = null;
                }
            }

            let apiBody = {
                inverter_data: this.inverter
            }
            try {
                // Show loading spinner
                this.loading = true;
                // Call Apollo mutate with original variables structure
                console.log("DEBUG ANDER: INVERTER DATA ADD => ", this.inverter)
                await this.$apollo.mutate({
                    mutation: gql`mutation AddInverters($inverterData: InvertersInput!) {
                      addInverters(inverterData: $inverterData) {
                          response {
                              ... on ResponseField {
                                  message
                                  ok
                              }
                              ... on AuthInfoField {
                                  message
                              }
                          }
                      }
                  }
              `,
                    variables: {
                        inverterData: this.inverter
                    }
                }).then((data) => {
                    console.log("DEBUG ANDER: RESPONSE => ", data)
                    
                    // console.log("DEBUG ANDER: RESPONSE 20000=> ", data)
                    this.closeModal()
                    // window.location.reload()
                    setTimeout(window.location.reload.bind(window.location), 1000);
                });
            } catch (error) {
                console.error('Error adding inverter data to db:', error);
            } finally {
                // Hide loading spinner
                this.loading = false;
            }
        },
        async getInverters() {
            const components = new Set();
            this.$apollo.query({
                query: gql`query Inverters {
                    inverters {
                        ... on InvertersList {
                            inverters {
                                id
                                deviceId
                                name
                                version
                                manufacturer
                            }
                        }
                        ... on AuthInfoField {
                            message
                        }
                    }
                }`
            }).then(response => {
                console.log("DEBUG UPDATE DATA: GET INVERTERS RESPONSE => ", response)
                const data = response.data;
                // Check if data is present and inverters array is not null
                if (data && data.inverters && Array.isArray(data.inverters.inverters)) {
                    const inverters = data.inverters.inverters.filter(inverter => !inverter.model && inverter.model !== null);
                    this.inverters = inverters
                    // Iterate over each inverter
                    inverters.forEach(inverter => {
                        // Log inverter data
                        console.log("Inverter data:", inverter);
                    });
                } else {
                    // Log error if data is null or inverters array is empty
                    console.error('No data or inverters array is empty');
                }
            })
                .catch(error => {
                    // Log error if there's an error fetching data
                    console.error('Error getting data from db:', error);
                });
        },
        buildMeterModel(jsonData) {
            return jsonData; // Return the built model
        },
        buildBMSModel(jsonData) {
            return jsonData;
        }
    },
    mounted() {
        this.getInverters()
    },
};
</script>

<style scoped>
.modal-enter-active,
.modal-leave-active {
    transition: opacity 0.3s, transform 0.3s;
}

.modal-enter,
.modal-leave-to {
    opacity: 0;
    transform: scale(0.9);
}

.file--input {
    outline: 1px solid;
    opacity: 1;
    position: absolute;
    /* z-index: 99999; */
    cursor: pointer;
}

.Fw-light {
    font-weight: 400;
    font-size: 0.8em;
}

.fontsize {
    font-size: 0.7em;
}

.upload--image {
    position: absolute;
    left: 8rem;
    /* background-color: #1b8eb8; */
    color: white;
    padding: 0.3rem 1rem;
    transition: all 300ms ease-in;
}

.file-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.file-input__label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 19.5px;
    /* background-color: #1b8eb8; */
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.file-input__label svg {
    height: 16px;
    margin-right: 4px;
}

.upload--image:hover {
    background-color: #22a4d3;
    color: white;
}

.modal--btns {
    justify-content: center;
}

.modal {
    position: relative;
}

.loadercontainer {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(211, 211, 211, 0.185);
}

.loader {
    width: 48px;
    height: 48px;
    display: block;
    margin: 15px auto;
    position: relative;
    top: 40%;
    color: #ccc;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.loader::after,
.loader::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    width: 24px;
    height: 24px;
    top: 50%;
    left: 50%;
    transform: scale(0.5) translate(0, 0);
    background-color: #ccc;
    border-radius: 50%;
    animation: animloader 1s infinite ease-in-out;
}

.loader::before {
    background-color: #000;
    transform: scale(0.5) translate(-48px, -48px);
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes animloader {
    50% {
        transform: scale(1) translate(-50%, -50%);
    }
}
</style>
