<template>
    <div>
      <h1 class="text-2xl text-gray-900 dark:text-gray-200 font-medium">Device Manager</h1>
    </div>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style>
  
  </style>