import "firebase/compat/auth";
import "firebase/compat/firestore";
import { graphqlMutations } from "@/store/store";
import logout from "@/jwt/auth";

export function isTokenExpired(token) {
  let expired = false;

  if (token) {
    // Split the token by dots to extract its parts
    const tokenParts = token.split(".");

    // Check if the token has three parts (header, payload, signature)
    if (tokenParts.length === 3) {
      try {
        // Decode second part of token from base64
        const decodedToken = atob(tokenParts[1]);

        // Parse the decoded token as a JSON object
        const tokenData = JSON.parse(decodedToken);

        const expirationTime = tokenData.exp;
        // Get the current time in seconds
        const currentTime = Math.floor(Date.now() / 1000);

        // Check if the token is expired
        if (expirationTime < currentTime) {
          // Additional verification of token validity
          expired = true;
        }
      } catch (error) {
        console.error("Error decoding or parsing token:", error);
      }
    }
  }

  return expired;
}

export const token = JSON.parse(sessionStorage.getItem("apollo-token"));
export const refToken = JSON.parse(sessionStorage.getItem("refreshToken"));
export const isAccessTokenExpired = isTokenExpired(token);
export const isRefreshTokenExpired = isTokenExpired(refToken);

export async function newAccessToken(apolloClient) {
  let accessToken = "";

  try {
    const data = await apolloClient.mutate({
      // Query
      mutation: graphqlMutations.refreshToken,
      variables: { token: refToken },
    });

    accessToken = JSON.stringify(data.data.refresh.token);
  } catch (error) {
    // Error
    console.error("Error in refreshToken mutation =>", error);
  }

  return accessToken;
}

export async function renewTokenIfNeeded(apolloClient) {
  if (token || refToken) {
    // Check if access token is expired or not exist
    if (!token || isAccessTokenExpired) {
      // Check if refresh token not exists
      if (!refToken) {
        return false;
      }

      // Check if refresh token is expired
      if (isRefreshTokenExpired) {
        return false;
      }

      // Renew access token
      const newToken = await newAccessToken(apolloClient);
      if (newToken) {
        // Token renewed successfully
        sessionStorage.setItem("apollo-token", newToken);
        return true;
      } else {
        // Handle token renewal failure
        return false;
      }
    }
  } else {
    return true;
  }
}

export default async function checkToken(apolloClient, router) {
  const newToken = await renewTokenIfNeeded(apolloClient);
  if (newToken === false) {
    //if new token null means ref expired or not valid
    logout(router);
  }
}
